

const url = {
    "service": process.env.REACT_APP_IPSERVER,
    "serviceImg": process.env.REACT_APP_IPIMG,
   // "service": "http://54.245.88.210:8081/",
   // "serviceImg": "http://54.245.88.210/",
    "logueado":"LOGUEADO",
    "llave":"LLAVE",
    "llavedefault":"",
    //llavedefault : '2s2vbnyynu36ofzkbuqyzgns5o9v75', // ahmii
    "llavemapa":"AIzaSyDVy5Hn71YqoLEisZTcpZ-OFmpScp6QJhA",
    colorprimario:'blue',
    colorsecundario:'green accent-2',
    colorllamativo:'red',
    empresa: undefined,
    sucursal: "sucursal1" ,
    userDefault : 'demo' ,
    correoDefault : 'demo' ,
    empresaJson : undefined ,


    capitalizeWords: function(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      },
      isMobileDevice : function(){
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        return /android|iphone|ipad|iPod/i.test(userAgent);
      }

}

export default url