import React, { Component } from "react";
import {
  MDBIcon, MDBContainer, MDBBadge, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBTableHead,
  MDBInput, MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import '../components/styles/NavBar.css'
import '../components/styles/InputNum.css'
import FooterPage from "../components/FooterPage";
import url from '../components/config/Config'

import { withRouter } from 'react-router-dom';

class Carrito extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          label: '',
          field: 'img',
        },
        {
          label: <strong>Producto</strong>,
          field: 'product'
        },
        {
          label: <strong>Precio</strong>,
          field: 'price'
        },
        {
          label: <strong>Cantidad</strong>,
          field: 'qty'
        },
        {
          label: <strong>Subtotal</strong>,
          field: 'amount'
        },
        {
          label: '',
          field: 'button'
        }
      ],
      total: 0,
      alerta: false,
      mensaje: '',
      login: false
    }
    if (localStorage.getItem('CarritoJucumari')) {
      let ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
      //console.log(JSON.parse(localStorage.getItem('CarritoJucumari')))
      this.state.data = ca
      //console.log(this.state.data)
    }
    this.CalcularTotal()
  }


  CalcularTotal() {
    let t = this.state.data
    let tot = 0
    for (var i = 0; i < t.length; i++) {
      tot = tot + (t[i].cantidad * t[i].precio)
    }
    this.state.total = tot
  }

  EliminarItem(idproducto) {
    let car = JSON.parse(localStorage.getItem('CarritoJucumari'))
    for (var i = 0; i < car.length; i++) {
      if ((car[i].idprod) == idproducto) {
        car.splice(i, 1)
      }
    }
    localStorage.setItem('CarritoJucumari', JSON.stringify(car))
    this.setState({data : car})
    window.dispatchEvent(new Event("storage"));
    this.CalcularTotal();
  }

  Decrementar(cant, idprod) {
    if (cant > 1) {
      let dec = JSON.parse(localStorage.getItem('CarritoJucumari'))
      for (var i = 0; i < dec.length; i++) {
        if ((dec[i].idprod) == idprod) {
          dec[i].cantidad = cant - 1
        }
      }
      localStorage.setItem('CarritoJucumari', JSON.stringify(dec))
      window.dispatchEvent(new Event("storage"));
      this.setState({data : dec})
       this.CalcularTotal();
    } else this.EliminarItem(idprod)
  }

  Incrementar(cant, idprod) {

    let inc = JSON.parse(localStorage.getItem('CarritoJucumari'))
    for (var i = 0; i < inc.length; i++) {
      if ((inc[i].idprod) == idprod) {
        inc[i].cantidad = cant + 1
      }
    }
    localStorage.setItem('CarritoJucumari', JSON.stringify(inc))
    window.dispatchEvent(new Event("storage"));
    this.setState({data : inc})
    this.CalcularTotal();
  }
  RealizarPedido = () => {
    const { history } = this.props;
    if (this.state.data.length > 0) {
      if (localStorage.getItem('LoginJucumari')) {
        if (localStorage.getItem('CarritoJucumari')) {
          history.push("/RealizarPedido" + '?e='.concat(localStorage.getItem('empresa')));
          //window.location.assign("/RealizarPedido" + '?e='.concat(localStorage.getItem('empresa')));
        } else {
          history.push("/Login" + '?e='.concat(localStorage.getItem('empresa')));
          //window.location.assign("/Login" + '?e='.concat(localStorage.getItem('empresa')));
        }
      } else {
        this.setState({ mensaje: 'Debe Iniciar Sesion' })
        this.setState({ alerta: !this.state.alerta });
        this.setState({ login: true })
      }
    } else {
      this.setState({ mensaje: 'Carrito Vacio' })
      this.setState({ alerta: !this.state.alerta });
    }
  }

  render() {
    const rows = [];
    const { columns, data } = this.state;
    const { history } = this.props;
    data.map(row => {
      return rows.push(
        {
          'img': <img src={row.imagen == null ? 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg' : row.imagen.replace('/var/www/html', url.serviceImg)} alt="" className="img-fluid z-depth-0" style={{ height: 50, width: 50 }} />,
          'product': [<h6 className="mt-3" key={new Date().getDate + 1}><strong>{row.nombrep}</strong></h6>],
          'price': `Bs. ${row.precio}`,
          'qty': <div className="def-number-input number-input">
            <button onClick={() => { this.Decrementar(row.cantidad, row.idprod) }} className="minus"></button>
            <input className="quantity" name="quantity" value={row.cantidad}
              type="number" />
            <button onClick={() => { this.Incrementar(row.cantidad, row.idprod) }} className="plus"></button>
          </div>,
          'amount': <strong>Bs. {(row.cantidad * row.precio).toFixed(2)}</strong>,
          'button':
            <MDBBtn color={url.colorprimario} size="sm" onClick={() => { this.EliminarItem(row.idprod) }}>
              <MDBIcon far icon="trash-alt" />
            </MDBBtn>
        }
      )
    });




    return (
      
      <div className="md-form mb-0">
        <MDBContainer>
          <br /><br />
          <h2 className="h1-responsive font-weight-bold text-center">
            Carrito de Compras
          </h2>
          <MDBModal backdrop={false} isOpen={this.state.alerta} toggle={() => {
            this.setState({ alerta: !this.state.alerta })
            if (this.state.login) {
              //window.location.assign("/Login" + '?e='.concat(localStorage.getItem('empresa')));
             
              // Redirigir a otra ruta
              history.push("/Login" + '?e='.concat(localStorage.getItem('empresa')));
            }
          }}>
            <MDBModalHeader toggle={() => {
              this.setState({ alerta: !this.state.alerta })
              if (this.state.login) {
                //window.location.assign("/Login" + '?e='.concat(localStorage.getItem('empresa')));
             
              // Redirigir a otra ruta
              history.push("/Login" + '?e='.concat(localStorage.getItem('empresa')));
              }
            }}>Error</MDBModalHeader>
            <MDBModalBody>
              {this.state.mensaje}
              <br />

            </MDBModalBody>
            <MDBBtn color='primary' onClick={() => {
              this.setState({ alerta: !this.state.alerta })
              if (this.state.login) {
              //window.location.assign("/Login" + '?e='.concat(localStorage.getItem('empresa')));
            
              // Redirigir a otra ruta
              history.push("/Login" + '?e='.concat(localStorage.getItem('empresa')));
              }
            }}>
              Aceptar
            </MDBBtn>
          </MDBModal>
          <MDBRow className="my-2" center>
            <MDBCard className="w-100">
              <MDBCardBody>
                <MDBTable className="product-table">
                  <MDBTableHead className="font-weight-bold" color="mdb-color lighten-5" columns={columns} />
                  <MDBTableBody rows={rows} />
                </MDBTable>
                <MDBRow>
                  <MDBCol md="8"><MDBBtn outline color={url.colorprimario} size="lg" block style={{ marginBottom: "1rem" }} onClick={this.RealizarPedido}>REALIZAR PEDIDO</MDBBtn></MDBCol>
                  <MDBCol md="4"><h3>Total Bs. <MDBBadge color={url.colorprimario}>{(this.state.total).toFixed(2)}</MDBBadge></h3></MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </MDBContainer>
        <br />
        <FooterPage />
      </div>

    );

  }
}
export default withRouter(Carrito)