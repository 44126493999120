import { MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBContainer, MDBIcon, MDBListGroupItem, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import React, { Component } from 'react';
import FooterPage from '../components/FooterPage';
import SectionContainer from '../components/sectionContainer';
import logo from '../images/ic_launcher_round.png'
import mifacodi from '../images/mifacodi.jpg'
class Mas extends Component{
    constructor(props){
        super(props);
        this.state = {
            modal5: false
        }
    }
    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
          [modalNumber]: !this.state[modalNumber]
        });
      }
    render(){
        return(
            <div>
            <MDBContainer>
            
                <MDBModal size="sm" cascading className="modal-avatar" isOpen={this.state.modal5} toggle={this.toggle(5)}>
                <MDBModalHeader className="mx-auto">
                    <img src={logo} alt="avatar" className="rounded-circle img-responsive" />
                </MDBModalHeader>
                <MDBModalBody className="text-center mb-1">
                    <h5 className="mt-1 mb-2">Tienda Jucumari</h5>
                    <p>Versión 1.0.1 - Tienda Jucumari</p>
                    <p>© Copyright MIFACODI</p>
                    <p>Aplicación Desarrollado Por</p>
                    <img style={{ width : 75 , height : 75}} src={mifacodi}/>
                </MDBModalBody>
              
                </MDBModal>
                <SectionContainer style={{marginTop : 50}} tag='section' header='Basic example'>
                
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem active>Datos Usuario</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <MDBListGroupItem href={'/Perfil'+'?e='.concat(localStorage.getItem('empresa'))} > <MDBIcon icon="user" />   Mi Perfil</MDBListGroupItem>
                    <MDBListGroupItem href={'/Pedido'+'?e='.concat(localStorage.getItem('empresa'))}>  <MDBIcon icon="shopping-cart" />   Mis Pedidos</MDBListGroupItem>
                    <br></br>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem active>Datos de la Empresa</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <MDBListGroupItem href={'/PerfilEmpresa'+'?e='.concat(localStorage.getItem('empresa'))}> <MDBIcon icon="home" />  Perfil Empresa</MDBListGroupItem>
                    <MDBListGroupItem href={'/Contacto'+'?e='.concat(localStorage.getItem('empresa'))}>  <MDBIcon icon="info-circle" />   Sugerencias</MDBListGroupItem>
                    <br></br>
                    <MDBBreadcrumb>
                        <MDBBreadcrumbItem active>Acerca de Nosotros</MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <MDBListGroupItem  >
                        <MDBBtn outline onClick={this.toggle(5)}> 
                           Nosotros
                        </MDBBtn>
                    </MDBListGroupItem>
                </SectionContainer>
                
                
            </MDBContainer>
            <FooterPage/>
            </div>
        )
    }
}

export default Mas;