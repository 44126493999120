import React, { useState, useEffect } from 'react'

import NavbarPage from '../components/NavbarPage'
import CarouselPage from '../components/CarouselPage'
import Productos from '../components/Productos'
import CarouselCategorias from '../components/CarouselCategorias'

import { MDBContainer, MDBRow, MDBCol, MDBListGroup, MDBListGroupItem, MDBBadge, MDBCard, MDBScrollbar } from "mdbreact";

import FooterPage from '../components/FooterPage'
import NavbarPageCategoria from '../components/NavbarPageCategoria'
import url from '../components/config/Config'

import img from '../images/banner-4.webp'
import '../styles/style-general.css'
import { useHistory , Link } from 'react-router-dom';
import Publicidad from './Publicidad'

const Main = ({ listaBanner = [],listaCategoriasItems = [],listaAllProductos = []}) => {

	const [listProductFilteredCategory, setListProductFiltered] = useState([]);
	const [currentBannerIndex, setCurrentBannerIndex] = useState(0); // Estado para el índice del banner actual



	const history = useHistory();

	
	useEffect(() => {
		if (listaBanner.length > 0) {
			const interval = setInterval(() => {
				setCurrentBannerIndex((prevIndex) =>
					prevIndex === listaBanner.length - 1 ? 0 : prevIndex + 1
				);
			}, 3000); // Cambia el banner cada 1 segundo

			return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
		}
	}, [listaBanner]);


	
	const categoryClick = (item) => {
		var url = `/categoria/${item.IdCategoria}/${item.Nombre}/1` + '?e='.concat(localStorage.getItem('empresa'));
		history.push(url);
	};

	const categoryClickMain = (categoria) => {
		console.log(".............................");
		console.log(categoria);
		console.log(listaAllProductos);
		let listTemp = listaAllProductos.filter((item) => item.idcategoria == categoria.IdCategoria);

		listTemp = listTemp.map((item) => ({
			...item,
			nombre: url.capitalizeWords(item.nombre),
			codigo: url.capitalizeWords(item.codigo),
		}));
		console.log(listTemp);
		setListProductFiltered(listTemp);
	};


	return (
		<div class="pt-10">
			<CarouselPage listaBanner = {listaBanner}/>
			{
				/*
<div className="carousel">
				{listaBanner.length > 0 ? (
					<div className="carousel-item">
						<img
							src={listaBanner[currentBannerIndex].path}
							className="img-fluid shadow-2-strong w-full"
							style={{ height: '500px', objectFit: 'cover' }} // Fija la altura a 500px
							alt={`Banner ${listaBanner[currentBannerIndex].id}`}
						/>
					</div>
				) : (
					<p>No hay banners disponibles</p>
				)}
			</div>
				*/
			}

			<MDBContainer>
			
			<MDBRow className="d-flex justify-content-center p-4">
				<h2 className="avy awj axd ayb">Categorias</h2>
			</MDBRow>

			<MDBRow className="d-flex justify-content-center">
				{listaCategoriasItems.map((item, index) => (
				<MDBCol 
					key={index} 
					className="d-flex justify-content-center align-items-center mb-4" 
					size="auto" // Esto ayuda a que las columnas se adapten al contenido y estén centradas
				>
					<div 
					className="groucategory-mouse-over position-relative"
					onClick={() => categoryClick(item)}
					>
					<div 
						className="position-relative overflow-hidden bg-white"
						style={{ height: '150px', width: '150px', borderRadius: '50%' }}
					>
						<img 
						src={item.Imagen.replace('/var/www/html', url.serviceImg)}
						className="h-100 w-100"
						style={{ objectFit: 'cover', objectPosition: 'center' }}
						alt={`Imagen de ${item.Nombre}`}
						/>
					</div>
					<h3 className="mt-3 text-center text-muted">
						<a href="#" className="text-reset">
						{url.capitalizeWords(item.Nombre)}
						</a>
					</h3>
					</div>
				</MDBCol>
				))}
			</MDBRow>
			</MDBContainer>


			<MDBContainer className="">
				<MDBRow>
					<MDBCol className="text-center pt-2">
						<h2 class ='avy awj axd ayb'>Encuentra todo lo que necesitas en un solo lugar.</h2>
					</MDBCol>
				</MDBRow>
			</MDBContainer>

			<MDBContainer>
				<p className="pt-3"></p>
				<div className="rounded bg-white border-t shadow-sm style-card-main" >
					<MDBRow className="h-100">
						<MDBCol md="3" clas="border-right-column h-full" >
							<div class="pl-4 pr-2">
								<div>
									<h2 className="avy awj axd ayb">Categorias</h2>
									<p className="lb awg axx pb-2">selecione una de nuestras categorias</p>
								</div>
								<hr></hr>
								<div>
									{
										listaCategoriasItems.map((cat, index) => {
											return (
												<div class="category-mouse-over">
													<MDBListGroupItem key={cat.uniqueId} className="d-flex align-items-center h-16 pt-3 pb-3" onClick={()=>{
														categoryClickMain(cat)
													}}  >
														<div class=""><img className="icon-size rounded-lg" src={cat.Imagen.replace('/var/www/html', url.serviceImg)} alt="" /></div>
														<div class="pl-2 text-sm text-gray-500">{url.capitalizeWords(cat.Nombre)}</div>
														<MDBBadge color="red" pill className="ml-auto">{cat.items}</MDBBadge>
													</MDBListGroupItem>
													<hr></hr>
												</div>
											)
												;
										})
									}

								</div>
							</div>

						</MDBCol>
						<MDBCol md="9"  className="scrollable-col pr-4">
							<div class="grid-container">
								{
									listProductFilteredCategory.map((prod, index) => (
										<div className="grid-item pt-4 flex flex-wrap w-full">
														<div className="mb yn aaz cfw chc chf dee dfl w-full p-2">
															<div className="bqi ab lx yy adn aea afg agb alt">
																<div className="e m aiq bfu bsf cdo">
																	<img
																		alt={prod.nombre}
																		src="http://mifacodi.com/assets/productoanonimo.jpg"
																		className="pn tu aqk aql cdq cem"
																	/>
																</div>
																<div className="lx ut yy abz aqz p-2 grid gap-2">
																	<h3 className="awg awk ayb">
																		<a href="#">
																			<span aria-hidden="true" className="aa ak"></span>
																			{prod.nombre}
																		</a>
																	</h3>
																	<p className="awg axx">Código : {prod.codigo}</p>
																	<div className="lx ut yy zk">
																		<p className="awe awk ayb">Bs {prod.precio}</p>
																	</div>
																	<div className="lk">
																		<a
																			href="#"
																			className="ab lx zg zl aeb afg ahf aio ars asb awg awk ayb big"
																		>
																			Añadir al carrito<span className="t"></span>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>	
									))
								}
							</div>
							
						</MDBCol>

					</MDBRow>
				</div>

				<MDBRow>
					<MDBCol>
						<Productos tipo={'masVendidos'} titulo={'Mas Vendidos'} />
					</MDBCol>
				</MDBRow>

				<MDBRow>
					<MDBCol>
						<Productos tipo={'ultimosAgregados'} titulo={'Ultimos Agregados'} />
					</MDBCol>
				</MDBRow>
				<Publicidad/>
			</MDBContainer>
			
			<FooterPage />


		</div>

	);



};

export default Main







