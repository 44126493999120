import React from 'react'
import ReactDOM from 'react-dom'
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './styles/tailwind-output.css'

import App from './components/App'


const container = document.getElementById('root')

ReactDOM.render(<App />, container)

