import React, { Component, Fragment } from "react";
import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBBadge, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBRow, MDBCol
} from "mdbreact";
import logo from '../images/ic_launcher_round.png'
import mifacodi from '../images/mifacodi.jpg'
import '../components/styles/NavBar.css'

import CategoriaMenuList from '../components/categoria/CategoriaMenuList'
import url from '../components/config/Config'

import { withRouter } from 'react-router-dom';


class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      showBtingresar: true, isOpen: false,
      modal5: false,
      listaCategorias: [],
      isMobile: url.isMobileDevice(),
      carrito: [],
      login: [],
      nombre: ''
    }
    if (localStorage.getItem('CarritoJucumari')) {
      let ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
      this.state.carrito = ca;
      this.setState({ carrito: ca })
    }
    if (localStorage.getItem('LoginJucumari')) {
      let l = JSON.parse(localStorage.getItem('LoginJucumari'))
      if (l.length > 0) {
        this.state.login = l;
        this.state.nombre = l[0].nombre;
        this.setState({ login: l });
        this.setState({ nombre: this.state.login[0].nombre });
      }
      this.state.showBtingresar = false
    } else {
      this.state.showLogin = false
    }
    this.listaCategoria();
    window.addEventListener('storage', (e) => {
      let ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
      if (ca) {
        this.setState({ carrito: ca })
      }
      let l = JSON.parse(localStorage.getItem('LoginJucumari'))
      if (l) {
        this.setState({ login: l });
        this.setState({ nombre: this.state.login[0].nombre });
        this.setState({ showBtingresar: false })
        this.setState({ showLogin: true })
        console.log(this.state);

      }
    })
  }


  listaCategoria() {
    let datos = {
      accion: 'listarCategoriaItems',
      llave: url.llavedefault
    }
    fetch(url.service + 'categoria', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        this.setState({ listaCategorias: Array.from(responseJson) });
        //this.listaProductoDescuento()
      });
  }




  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  CerrarSesion() {
    let datos = {
      correo: url.userDefault,
      pass: url.correoDefault,
      empresa: url.empresa,
      sucursal: url.sucursal,
    }
    fetch(url.service + 'loginClienteEmpresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        let lista = Array.from(responseJson);
        let params = {
          e: lista[0].label.split('-')[0],
          s: lista[0].label.split('-')[1],
          d: '12346-7888',
          id: lista[0].id[0].idcliente
        };
        fetch(url.service + 'jucumariCliente', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(params) }).then((response) =>
          response.json()).then((responseJson) => {
            //console.log(responseJson)
            if (responseJson.llave) {
              url.llavedefault = responseJson.llave;
              localStorage.removeItem('LoginJucumari')
              window.location.assign("/" + '?e='.concat(localStorage.getItem('empresa')));
            }
          })
      });
  }


  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  render() {
    const { showLogin, showBtingresar } = this.state
    const { history } = this.props;
    return (
      <div>
        {!this.state.isMobile ?
          <MDBNavbar color="white"
            expand="md" fixed="top" scrolling>
            <MDBModal size="sm" cascading className="modal-avatar" isOpen={this.state.modal5} toggle={this.toggle(5)}>
              <MDBModalHeader className="mx-auto">
                <img src={logo} alt="avatar" className="rounded-circle img-responsive" />
              </MDBModalHeader>
              <MDBModalBody className="text-center mb-1">
                <h5 className="mt-1 mb-2">Tienda Jucumari</h5>
                <p>Versión 1.0.1 - Tienda Jucumari</p>
                <p>© Copyright MIFACODI</p>
                <p>Aplicación Desarrollado Por</p>
                <img style={{ width: 75, height: 75 }} src={mifacodi} />
              </MDBModalBody>

            </MDBModal>
            <MDBContainer color={url.colorprimario}>

              <MDBNavbarBrand >
                <MDBNavLink to={"/" + '?e='.concat(localStorage.getItem('empresa'))}> <MDBIcon icon="home" /> <strong className="black-text" >Jucumari</strong></MDBNavLink>
              </MDBNavbarBrand>
              <MDBNavbarToggler onClick={this.toggleCollapse} />
              <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <MDBIcon fas icon="bars" /> <strong><span className="mr-2 font-sans black-text"> Categorías {this.state.isMobile}</span></strong>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <CategoriaMenuList categorias={this.state.listaCategorias} />
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to={"/contacto" + '?e='.concat(localStorage.getItem('empresa'))}> <MDBIcon far icon="envelope" /><strong className="black-text" >Contáctanos</strong></MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <MDBNavLink className="waves-effect waves-white" to={"/Carrito" + '?e='.concat(localStorage.getItem('empresa'))}>

                      <MDBIcon icon="shopping-cart" /> Carrito
                      <span className="align-top">
                        <MDBBadge color={url.colorllamativo} className="ml-2">{this.state.carrito.length}</MDBBadge>
                      </span>
                    </MDBNavLink>

                  </MDBNavItem>
                  {showLogin && (<MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <MDBIcon icon="user" /> <strong className="black-text" >Perfil</strong>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu className="dropdown" style={{ width: '250px' }} right>
                        <div class="dropdown-header w-full bg-slate-400">Datos de Usuario</div>
                        <hr></hr>
                        <MDBDropdownItem className='dropdown-item' onClick={() => {
                          history.push("/Perfil" + '?e='.concat(localStorage.getItem('empresa')));
                        }} > <MDBIcon icon="user" style={{ height: '40px', lineHeight: '40px' }} class="pl1" /> {this.state.nombre}</MDBDropdownItem>
                        <MDBDropdownItem className='dropdown-item' onClick={this.CerrarSesion}> <MDBIcon icon="sign-out-alt" /> Cerrar Sesion</MDBDropdownItem>
                        <MDBDropdownItem className='dropdown-item' onClick={() => {
                          history.push("/Pedido" + '?e='.concat(localStorage.getItem('empresa')));
                        }}> <MDBIcon icon="shopping-cart" />   Mis Pedidos</MDBDropdownItem>

                        <hr></hr>
                        <h1 class="dropdown-header">Datos de la Empresa</h1>
                        <hr></hr>
                        <MDBDropdownItem className='dropdown-item' onClick={() => {
                          history.push("/PerfilEmpresa" + '?e='.concat(localStorage.getItem('empresa')));
                        }} ><MDBIcon icon="home" />  Perfil Empresa</MDBDropdownItem>
                        <MDBDropdownItem className='dropdown-item' onClick={() => {
                          history.push("/Contacto" + '?e='.concat(localStorage.getItem('empresa')));
                        }}><MDBIcon icon="info-circle" />   Sugerencias</MDBDropdownItem>

                        <hr></hr>
                        <h1 class="dropdown-header">Acerca de Nosotros</h1>
                        <hr></hr>
                        <MDBDropdownItem className='dropdown-item' onClick={this.toggle(5)} ><MDBIcon icon="question-circle" />  Nosotros</MDBDropdownItem>
                      </MDBDropdownMenu>


                    </MDBDropdown>
                  </MDBNavItem>)}
                  {showBtingresar && (<MDBNavItem>
                    <Fragment>

                    <div class='btn-iniciar-session' 
                      onClick={() => { history.push("/Login" + '?e='.concat(localStorage.getItem('empresa')));}}> Iniciar sesión</div>


                    </Fragment>
                  </MDBNavItem>)}
                </MDBNavbarNav>
              </MDBCollapse>

            </MDBContainer>
          </MDBNavbar>
          :
          <div>{/* Design Movil Begin */}
            <MDBNavbar color="white"
              expand="md" fixed="top" scrolling>

              <MDBContainer color={url.colorprimario}>

                <MDBNavbarNav left>

                  <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav>
                        <MDBIcon fas icon="bars" /> {/* icon category*/}
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <CategoriaMenuList categorias={this.state.listaCategorias} />
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>

                <MDBNavbarNav right>
                  {showLogin && (
                    <MDBRow>
                      <MDBCol>
                        <MDBNavLink className="waves-effect waves-white" to={"/Carrito" + '?e='.concat(localStorage.getItem('empresa'))}>


                          <span className="align-top">
                            <MDBBadge color={url.colorllamativo} className="ml-2"><MDBIcon icon="shopping-cart" /> {this.state.carrito.length}</MDBBadge>
                          </span>
                        </MDBNavLink>
                      </MDBCol>
                      <MDBCol>
                        <MDBDropdown>
                          <MDBDropdownToggle nav caret>
                            <MDBIcon icon="user" /> <strong className="black-text" ></strong>
                          </MDBDropdownToggle>
                          <MDBDropdownMenu className="dropdown" style={{ width: '250px' }} right>
                            <div class="dropdown-header w-full bg-slate-400">Datos de Usuario</div>
                            <hr></hr>
                            <MDBDropdownItem className='dropdown-item' onClick={() => {
                              history.push("/Perfil" + '?e='.concat(localStorage.getItem('empresa')));
                            }} > <MDBIcon icon="user" style={{ height: '40px', lineHeight: '40px' }} class="pl1" /> {this.state.nombre}</MDBDropdownItem>
                            <MDBDropdownItem className='dropdown-item' onClick={this.CerrarSesion}> <MDBIcon icon="sign-out-alt" /> Cerrar Sesion</MDBDropdownItem>
                            <MDBDropdownItem className='dropdown-item' onClick={() => {
                              history.push("/Pedido" + '?e='.concat(localStorage.getItem('empresa')));
                            }}> <MDBIcon icon="shopping-cart" />   Mis Pedidos</MDBDropdownItem>

                            <hr></hr>
                            <h1 class="dropdown-header">Datos de la Empresa</h1>
                            <hr></hr>
                            <MDBDropdownItem className='dropdown-item' onClick={() => {
                              history.push("/PerfilEmpresa" + '?e='.concat(localStorage.getItem('empresa')));
                            }} ><MDBIcon icon="home" />  Perfil Empresa</MDBDropdownItem>
                            <MDBDropdownItem className='dropdown-item' onClick={() => {
                              history.push("/Contacto" + '?e='.concat(localStorage.getItem('empresa')));
                            }}><MDBIcon icon="info-circle" />   Sugerencias</MDBDropdownItem>

                            <hr></hr>
                            <h1 class="dropdown-header">Acerca de Nosotros</h1>
                            <hr></hr>
                            <MDBDropdownItem className='dropdown-item' onClick={this.toggle(5)} ><MDBIcon icon="question-circle" />  Nosotros</MDBDropdownItem>
                          </MDBDropdownMenu>


                        </MDBDropdown>
                      </MDBCol>
                    </MDBRow>
                  )}
                  {showBtingresar && (
                    <MDBNavItem>
                      <Fragment>
                        <MDBBtn color={url.colorprimario} size="sm" onClick={this.Login} > <div>Iniciar sesión</div>
                        </MDBBtn>
                      </Fragment>
                    </MDBNavItem>)}
                </MDBNavbarNav>

              </MDBContainer>
            </MDBNavbar>

            {/* Design Movil End */}
          </div>
        }
      </div>

    );
  }
}

export default withRouter(NavbarPage);