import React, { Component } from "react";
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse,
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBIcon, MDBContainer, MDBAutocomplete
} from "mdbreact";
import '../components/styles/NavBar.css'

import CategoriaMenuList from './categoria/CategoriaMenuList'
import url from './config/Config'


import KeyboardEventHandler from 'react-keyboard-event-handler';
class NavbarPage extends Component {

  constructor(props) {
    super(props);
    this.st = {
      carrito: [],
      login: [],
      nombre: '' ,
    
    }
    this.state = {
      showLogin: true,
      showBtingresar: true, isOpen: false ,
      buscar : '' ,
      listaProductos : [] ,
      states : [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illnois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ]
    } 
    this.productos()
    if (localStorage.getItem('CarritoJucumari')) {
      let ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
      this.st.carrito = ca
      //localStorage.clear();
    }
    //console.log(this.st.carrito)
    if (localStorage.getItem('LoginJucumari')) {
      let l = JSON.parse(localStorage.getItem('LoginJucumari'))
      if (l.length > 0) {
        this.st.login = l
        this.st.nombre = this.st.login[0].nombre
      }
      this.state.showBtingresar = false
      //this.setState({showBtingresar:false})
    } else {
      this.state.showLogin = false
    }
    //console.log(this.st.login)
  }

/*
async buscar(text){
    if (text) {
      const response = await fetch(`https://suggestqueries.google.com/complete/search?client=firefox&q=${text}`, {
          method: `get`
      });
      const data = await response.json();
      //console.log(data)
      return data[1];
  } else {
      return [];
  }
}*/

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  Login = () => {

    window.location.assign("/Login"+'?e='.concat(localStorage.getItem('empresa')));
  }

  CerrarSesion() {
    localStorage.removeItem('LoginJucumari')
    window.location.assign("/"+'?e='.concat(localStorage.getItem('empresa')));
  }
  // this.props.history.push('/exercise')


 productos() {

    let datos = {
      accion: 'listarProductoId',
      llave: url.llavedefault,
      id: '{-1}'
    }
    fetch(url.service+'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
       //this.setState({listaProductos : responseJson})
       let temp = [] 
       responseJson.forEach((element,index,array) => {
        /* let dato = {
           id : element.idproducto ,
           name : element.nombre
         }*/
         temp.push(element.nombre);
         if (index === array.length - 1) {
          this.setState({listaProductos : temp})
         }
        
       });
   })
  }
   handleOnSearch = (string, cached) => {
    // onSearch returns the string searched and if
    // the values are cached. If the values are cached
    // "cached" contains the cached values, if not, returns false
   // console.log(string, cached);
     this.setState({buscar : string})
  }
   handleOnSelect = item => {
    // the item selected
   // console.log(item);
    let url = "/categoria/@id/@nombre/2"+'?e='.concat(localStorage.getItem('empresa'));
    url = url.replace('@id' , item.id);
    url = url.replace('@nombre',item.name)
    window.location.assign(url)
  }

   handleOnFocus = () => {
    //console.log("Focused");
  }

  logValue = value => {
    this.setState({buscar : value})
  };
  render() {
    const { showLogin, showBtingresar } = this.state
    return (
      <MDBNavbar color={url.colorprimario}
        dark expand="xs" >
        <MDBContainer>
          
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="mr-2"> <MDBIcon icon="bars"  /> Categoría de Productos</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <CategoriaMenuList categorias={this.props.categorias} />
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>

              

            </MDBNavbarNav>
                            {<KeyboardEventHandler handleKeys={['all']}  onKeyEvent={(key, e) => {
                                    if (key == 'enter' && this.state.buscar.length > 0) {
                                      let url = "/categoria/@id/@nombre/2"+'?e='.concat(localStorage.getItem('empresa'));
                                      url = url.replace('@id' , 1);
                                      url = url.replace('@nombre',this.state.buscar)
                                      window.location.assign(url)
                                    }
                              }}>
                                 <MDBAutocomplete
                                    data={this.state.listaProductos}
                                    icon='search'
                                    label="Buscar...."
                                    labelClass='text-light'
                                    clear
                                    getValue={this.logValue}
                                  />
                                {/*<div style={{width: window.innerWidth * 0.25}} >
                                    <ReactSearchAutocomplete
                                      items={this.state.listaProductos}
                                      onSearch={this.handleOnSearch}
                                      onSelect={this.handleOnSelect}
                                      onFocus={this.handleOnFocus}
                                      autoFocus
                                    />
                                  </div>*/}
                            </KeyboardEventHandler>}
                
    
          </MDBCollapse>

        </MDBContainer>
      
      </MDBNavbar>
    );
  }
}

export default NavbarPage;