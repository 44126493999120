import {  MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from 'mdbreact';
import React, { Component } from 'react';
import url from '../components/config/Config';
import SectionContainer from '../components/sectionContainer';

class PerfilEmpresa extends Component{
    constructor(props){
        super(props);
        this.state = {
            lista : []
        }
        this.listaFooter()
    }
    listaFooter() {
        let datos = {
          accion: 'getEmpresa',
          llave: url.llavedefault
        }
        fetch(url.service+'empresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
          response.json()).then((responseJson) => {
            this.setState({ lista: Array.from(responseJson) });
            //console.log(responseJson)
        });
    }
    render(){
        return(
            <div>
                
                <SectionContainer style={{marginTop : 50}} tag='section' header='Basic example'>
                 <section className="my-5">
                    <h1 className="h1-responsive font-weight-bold text-center my-5">
                        {this.state.lista[0] ?  this.state.lista[0].nombre : ''}
                    </h1>
                    
                    <MDBRow>
                        <MDBCol lg="5" className="lg-0 mb-4">
                        <MDBCard>
                            <MDBCardBody  style={{ height: "400px" }}>
                           
                            <p className="dark-grey-text">
                               {this.state.lista[0]? this.state.lista[0].ciudad : ''} - {this.state.lista[0] ? this.state.lista[0].pais : ''}
                            </p>
                            <div className="md-form">
                                <MDBInput
                                icon="user"
                                label="nombre"
                                iconClass="grey-text"
                                type="text"
                                id="form-name"
                                value= {this.state.lista[0]? this.state.lista[0].nombre : ''}
                                disabled="true"
                                />
                            </div>
                         
                            <div className="md-form">
                                <MDBInput
                                icon="tag"
                                label="direccion"
                                iconClass="grey-text"
                                type="text"
                                id="form-subject"
                                value={this.state.lista[0]? this.state.lista[0].direccion : ''}
                                disabled="true"
                                />
                            </div>
                            <div className="md-form">
                                <MDBInput
                                icon="phone"
                                label="telefono"
                                iconClass="grey-text"
                                type="text"
                                id="form-subject"
                                value={this.state.lista[0]? this.state.lista[0].telefono : ''}
                                disabled="true"
                                ac
                                />
                            </div>
                            <div className="md-form">
                                <MDBInput
                                icon="city"
                                label="Ciudad"
                                iconClass="grey-text"
                                type="text"
                                id="form-name"
                                value= {this.state.lista[0]? this.state.lista[0].ciudad : ''}
                                disabled="true"
                                />
                            </div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        <MDBCol lg="7">
                        <div
                            id="map-container"
                            className="rounded z-depth-1-half map-container"
                            style={{ height: "400px" }}
                        >
                            <iframe
                            src="https://www.google.com/maps?q=Mifacodi&output=embed"
                            title={this.state.lista[0]? this.state.lista[0].nombre : ''}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                            />
                        </div>
                        <br />
                       
                        </MDBCol>
                    </MDBRow>
                    </section>
                    </SectionContainer>
            </div>
        )
    }
}

export default PerfilEmpresa;