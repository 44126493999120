import React from "react";
import { MDBModal, MDBContainer, MDBRow, MDBModalHeader, MDBCol, MDBModalBody, MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import '../components/styles/NavBar.css'
import FooterPage from "../components/FooterPage";
import url from '../components/config/Config'
import '../styles/style-general.css'
class Contacto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listaVals: [],
      nombre: '',
      correo: '',
      telefono: '',
      mensaje: '',
      modalAlerta: false
    }

  }
  enviarSugerencia(me) {
    fetch(url.service + 'sugerenciaReact', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify({ llave: url.llavedefault, cliente: me, sugerencia: this.state.mensaje }) }).then(response => {
      console.log(response)
      this.setState({ modalAlerta: true })
    })

  }
  submitHandler = event => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (this.state.nombre.length > 0 && this.state.correo.length > 0 && this.state.telefono.length > 0 && this.state.mensaje.length > 0) {
      let m = this.state.nombre.concat('\n').concat(this.state.correo).concat('\n').concat(this.state.telefono).concat('\n')
      this.enviarSugerencia(m)
    }

  };
  render() {
    return (

      <div class="flex flex-row items-center justify-center">
        <div className="md-form contacto-style mx-auto">
          <h2 className="h1-responsive font-weight-bold text-center">
            Contáctanos
          </h2>
          <p className="text-center w-responsive mx-auto pb-5">
            Puedes dejarnos tus sugerencias o solicitarnos cualquier información que necesites.
          </p>
          <MDBContainer>

            <MDBModal backdrop={false} isOpen={this.state.modalAlerta} toggle={() => {
              this.setState({ modalAlerta: !this.state.modalAlerta })

            }}>
              <MDBModalHeader toggle={() => {
                this.setState({ modalAlerta: !this.state.modalAlerta })

              }}>Mensaje</MDBModalHeader>
              <MDBModalBody>
                Sugerencia Enviada
              </MDBModalBody>
              <MDBBtn color='primary' onClick={() => {
                this.setState({ modalAlerta: !this.state.modalAlerta })

              }}>
                Aceptar
              </MDBBtn>
            </MDBModal>
            <MDBRow>
              <MDBCol>
                <form className='needs-validation'
                  onSubmit={this.submitHandler}
                  noValidate>
                  <p className="h5 text-center mb-4">Escríbenos</p>
                  <div className={url.colorprimario + "-text"}>
                    <MDBInput label="Tu nombre completo" icon="user" group type="text" required error="wrong"
                      success="right" required value={this.state.nombre} onChange={(e) => this.setState({ nombre: e.target.value })} />

                    <MDBInput label="Tu correo electrónico" icon="envelope" group type="email" validate error="wrong"
                      success="right" required value={this.state.correo} onChange={(e) => this.setState({ correo: e.target.value })} />
                    <MDBInput label="Teléfono" required icon="phone" group type="text" validate error="wrong" success="right" value={this.state.telefono} onChange={(e) => this.setState({ telefono: e.target.value })} />
                    <MDBInput type="textarea" required rows="2" label="Tu mensaje" error="wrong" icon="pencil-alt" value={this.state.mensaje} onChange={(e) => this.setState({ mensaje: e.target.value })} />
                  </div>
                  <div className="text-center">
                    {/*<MDBBtn outline color={url.colorprimario} onClick={()=>{
              let m = this.state.nombre.concat('\n').concat(this.state.correo).concat('\n').concat(this.state.telefono).concat('\n')
              this.enviarSugerencia(m)
             
          }} >
            Enviar
            <MDBIcon far icon="paper-plane" className="ml-1" />
          </MDBBtn>*/}


                    <MDBBtn outline color={url.colorprimario} type='submit'>
                      Enviar
                      <MDBIcon far icon="paper-plane" className="ml-1" />
                    </MDBBtn>
                  </div>
                </form>
              </MDBCol>
            </MDBRow>
          </MDBContainer>


        </div>
      </div>

    )
  }
}
export default Contacto