import React, { Component } from "react";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";
import FooterPage from "../components/FooterPage";
import url from '../components/config/Config'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemOuterTabs: "1",
      activeItemInnerPills: "1",
      nombre: '',
      correo: '',
      pass: '',
      registro: [],
      usuariol: '',
      contrasenal: '',
      sesion: [],
      log: [],
      key: '',
      modalAlerta: false,
      mensajeAlerta: '',
      tituloMensaje: 'Error de Datos',
      load: false
    }
    if (localStorage.getItem('LoginJucumari')) {
      window.history.back()
    }
  }
  toggleOuterTabs = tab => e => {
    if (this.state.activeItemOuterTabs2 !== tab) {
      this.setState({
        activeItemOuterTabs: tab
      });
    }
  };

  toggleInnerPills = tab => e => {
    if (this.state.activeItemInnerPills !== tab) {
      this.setState({
        activeItemInnerPills: tab
      });
    }
  };

  handleChange(e) {
    this.setState({ nombre: e.target.value });
  }

  handleChange2(e) {
    this.setState({ correo: e.target.value });
  }

  handleChange3(e) {
    this.setState({ pass: e.target.value });
  }

  handleChange4(e) {
    this.setState({ usuariol: e.target.value });
  }

  handleChange5(e) {
    this.setState({ contrasenal: e.target.value });
  }

  Registrar = () => {
    if (this.state.nombre != '' && this.state.correo != '' && this.state.pass != '') {
      let datos = {
        accion: 'agregarClienteOnline',
        empresa: url.empresa,
        sucursal: url.sucursal,
        nombre: this.state.nombre,
        correo: this.state.correo,
        pass: this.state.pass
      }
      fetch(url.service + 'cliente', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
        response.json()).then((responseJson) => {
          this.setState({ registro: Array.from(responseJson) });
          //console.log(this.state.registro)
          if (this.state.registro[0].cliente_insertar > 0) {
            this.setState({ load: true })
            this.setState({ tituloMensaje: "Correcto" })
            this.setState({ mensajeAlerta: 'Registrado Correctamente!!!' });
            this.setState({ modalAlerta: !this.state.modalAlerta })
           
          } else {
            this.setState({ mensajeAlerta: 'Datos Incorrectos o ya registrados!!!' });
            this.setState({ modalAlerta: !this.state.modalAlerta })
          }
        });
    }
    else {
      // alert("Complete todos los campos.!!!")
      this.setState({ mensajeAlerta: 'Complete todos los campos.!!!' });
      this.setState({ modalAlerta: !this.state.modalAlerta })
    }
  }
  Ingresar = () => {
    if (this.state.usuariol != '' && this.state.contrasenal != '') {
      let data = {
        correo: this.state.usuariol,
        pass: this.state.contrasenal,
        empresa: url.empresa,
        sucursal: url.sucursal,
        userinfo: {}
      }
      //console.log(data)
      fetch(url.service + 'loginClienteEmpresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(data) }).then((response) =>
        response.json()).then((responseJson) => {
          this.setState({ sesion: Array.from(responseJson) });
          this.setState({ log: this.state.sesion[0].id });
          //console.log('cli' ,responseJson )
          if (this.state.log.length > 0) {
            let em = []
            em = (this.state.sesion[0].label).split("-", 2)
            //console.log(em)
            let data2 = {
              e: em[0],
              s: em[1],
              d: '21345d',
              id: this.state.log[0].idcliente
            }

            //console.log(data2)

            fetch(url.service + 'jucumariCliente', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(data2) }).then((response) =>
              response.json()).then((responseJson) => {
                //console.log('login .........' ,responseJson)
                this.setState({ key: responseJson.llave });
                //cambiar cahe logueado a  '1' CACHE
                //console.log(this.state.key);
                url.llavedefault = responseJson.llave;
                let CadenaLogin = [
                  {
                    "idcliente": this.state.log[0].idcliente,
                    "nombre": this.state.log[0].nombre,
                    "correo": this.state.log[0].correo,
                    "llave": this.state.key,
                    telefono: this.state.log[0].telefono,
                    direccion: this.state.log[0].direccion,
                  }]

                localStorage.setItem('LoginJucumari', JSON.stringify(CadenaLogin))
                window.location.assign("/"+'?e='.concat(localStorage.getItem('empresa')))
              });

          } else {
            this.setState({ mensajeAlerta: 'Usuario o Contraseña incorrectos!!!' });
            this.setState({ modalAlerta: !this.state.modalAlerta })
          }
        });

    } else {
      this.setState({ mensajeAlerta: 'Debe llenar los Campos' });
      this.setState({ modalAlerta: !this.state.modalAlerta })
    }
    //console.log(this.state.usuariol + this.state.contrasenal)
  }

  render() {
    return (
      <div className="md-form mb-0">
        <MDBContainer>
          <br />
          <h2 className="h1-responsive font-weight-bold text-center">Iniciar Sesion</h2>
          <MDBModal backdrop={false} isOpen={this.state.modalAlerta} toggle={() => {
            this.setState({ modalAlerta: !this.state.modalAlerta })
            this.setState({
              activeItemOuterTabs: "1"
            });
            if (this.state.load) {
              // window.dispatchEvent(new Event("storage"));
            }
          }}>
            <MDBModalHeader toggle={() => {
              this.setState({ modalAlerta: !this.state.modalAlerta })
              this.setState({
                activeItemOuterTabs: "1"
              });
              if (this.state.load) {
                //window.dispatchEvent(new Event("storage"));
              }
            }}>{this.state.tituloMensaje}</MDBModalHeader>
            <MDBModalBody>
              {this.state.mensajeAlerta}

            </MDBModalBody>
            <MDBBtn color='primary' onClick={() => {
              this.setState({ modalAlerta: !this.state.modalAlerta })
              this.setState({
                activeItemOuterTabs: "1"
              });
              if (this.state.load) {
                //window.dispatchEvent(new Event("storage"));
              }
            }}>
              Aceptar
            </MDBBtn>
          </MDBModal>
          <MDBNav tabs className="nav-justified" color={url.colorprimario}>
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItemOuterTabs === "1"} onClick={this.toggleOuterTabs("1")} role="tab" >
                <MDBIcon icon="user" /> Ingresar
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItemOuterTabs === "2"} onClick={this.toggleOuterTabs("2")} role="tab" >
                <MDBIcon icon="user-plus" /> Regístrate
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent
            className="card mb-5"
            activeItem={this.state.activeItemOuterTabs}
          >
            <MDBTabPane tabId="1" role="tabpanel">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <form>
                      <p className="h5 text-center mb-4">Ingresar</p>
                      <div className="grey-text">
                        <MDBInput label="Usuario" icon="envelope" group type="email" validate error="wrong"
                          success="right" value={this.state.usuariol ? this.state.usuariol : ''} onChange={e => this.handleChange4(e)} />
                        <MDBInput label="Contraseña" icon="lock" group type="password" validate value={this.state.contrasenal ? this.state.contrasenal : ''} onChange={e => this.handleChange5(e)} />
                      </div>
                      <div className="text-center">
                        <MDBBtn color={url.colorprimario} onClick={this.Ingresar}>Login</MDBBtn>
                      </div>
                    </form>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>


                    <p className="h4 text-center py-4">Regístrate</p>
                    <div className="grey-text">
                      <MDBInput
                        label="Nombre Completo"
                        icon="user"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value={this.state.nombre ? this.state.nombre : ''} onChange={e => this.handleChange(e)}
                      />
                      <MDBInput
                        label="Correo Electrónico"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={this.state.correo ? this.state.correo : ''} onChange={e => this.handleChange2(e)}
                      />

                      <MDBInput
                        label="Contraseña"
                        icon="lock"
                        group
                        type="password"
                        validate
                        value={this.state.pass ? this.state.pass : ''} onChange={e => this.handleChange3(e)}
                      />
                    </div>
                    <div className="text-center py-4 mt-3">
                      <MDBBtn color={url.colorprimario} onClick={this.Registrar}>
                        Registrar
                      </MDBBtn>
                    </div>

                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBTabPane>
          </MDBTabContent>
        </MDBContainer>
        <br />
        <FooterPage />
      </div>
    );
  }
}
export default Login