import React, { Component } from "react";
import { MDBContainer,  MDBRow, MDBCol,MDBInput, MDBBtn 
  } from "mdbreact";

import '../components/styles/NavBar.css'
import '../components/styles/InputNum.css'
import FooterPage from "../components/FooterPage";
import SectionContainer from '../components/sectionContainer';
class Perfil extends Component {
    constructor(props){
        super(props);
        this.state = {
            nombre : '' ,
            correo : '' ,
            direccion : '' ,
            telefono : '' , 
            idcliente : '' ,
            llave : ''
        }
        if (localStorage.getItem('LoginJucumari')) {
            let l = JSON.parse(localStorage.getItem('LoginJucumari'))
            //console.log(l)
            if (l.length > 0) {
                this.state.nombre =  l[0].nombre
                this.state.correo =  l[0].correo
                this.state.telefono =  l[0].telefono 
                this.state.direccion =  l[0].direccion
                this.state.idcliente =  l[0].idcliente
                this.state.llave =  l[0].llave
              }
          }
    }
    
    modificar(){
        let CadenaLogin = [
            {
              "idcliente": this.state.idcliente,
              "nombre":this.state.nombre,
              "correo":this.state.correo,
              "llave": this.state.llave		,
              telefono : this.state.telefono, 
              direccion : this.state.direccion,   
            }]
           
            localStorage.setItem('LoginJucumari', JSON.stringify(CadenaLogin))
            window.history.go(-1)
    }

    render(){
        return(
            <div>
            <MDBContainer>
                <SectionContainer tag='section' header='Basic example' style={{marginTop : 50}}>
                    <h2 className='h1-responsive font-weight-bold text-center my-5'>
                    Mi Perfil
                    </h2>
                   
                    <MDBRow>
                    <MDBCol md='12' className='md-0 mb-5'>
                        <form>
                        <MDBRow>
                            <MDBCol md='6'>
                            <div className='md-form mb-0'>
                                <MDBInput type='text' id='contact-name' label='Nombre' onChange={e => this.setState({nombre : e.target.value})} value={this.state.nombre} />
                            </div>
                            </MDBCol>
                            <MDBCol md='6'>
                            <div className='md-form mb-0'>
                                <MDBInput
                                type='text'
                                id='contact-email'
                                label='Correo'
                                onChange={e => this.setState({correo : e.target.value})} value={this.state.correo}  />
                            </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12'>
                            <div className='md-form mb-0'>
                                <MDBInput
                                type='text'
                                id='contact-subject'
                                label='Direccion'
                                onChange={e => this.setState({direccion : e.target.value})} value={this.state.direccion} />
                            </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md='12'>
                            <div className='md-form mb-0'>
                                <MDBInput
                                type='number'
                                id='contact-message'
                                label='Telefono'
                                onChange={e => this.setState({telefono : e.target.value})} value={this.state.telefono} />
                            </div>
                            </MDBCol>
                        </MDBRow>
                        </form>
                        <div className='text-center text-md-left'>
                        <MDBBtn color='primary' size='md' onClick={()=>{
                            this.modificar()
                        }}>
                            Guardar
                        </MDBBtn>
                        </div>
                    </MDBCol>
                   
                    </MDBRow>
                </SectionContainer>
              
            </MDBContainer>
              <FooterPage/>
              </div>
        )
    }
}

export default Perfil;