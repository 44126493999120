import React from 'react'
import {
	MDBContainer, MDBBadge,
	 MDBBreadcrumb, MDBBreadcrumbItem, MDBBtn, MDBCollapse, MDBListGroup, MDBListGroupItem
} from "mdbreact";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import FooterPage from '../components/FooterPage'
import url from  '../components/config/Config'

class DetalleProducto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			idp:this.props.match.params.idproducto,
			listaCaracteristicas: [],
			listaEspecificaciones: [],
			listaImagenes: [],
			lista: [],
			llave: url.llavedefault,
			collapseID: "basicCollapse",
			collapseID2: ""
		}
		this.listaDetalleProducto()
		//console.log(this.state.idp)
	}

	toggleCollapse = collapseID => () => {
		this.setState(prevState => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : ""
		}));
	}
	toggleCollapse2 = collapseID2 => () => {
		this.setState(prevState => ({
			collapseID2: prevState.collapseID2 !== collapseID2 ? collapseID2 : ""
		}));
	}

	listaDetalleProducto() {

		let datos = {
			accion: 'detalles',
			llave: url.llavedefault,
			idproducto: this.props.match.params.idproducto
		}
		fetch(url.service+'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
			response.json()).then((responseJson) => {
				this.setState({ lista: Array.from(responseJson) });
				if (this.state.lista[0].caracteristicas==null)
				this.setState({ listaCaracteristicas: [] })
				else 
				this.setState({ listaCaracteristicas: this.state.lista[0].caracteristicas })
				if (this.state.lista[0].esptecnicas==null)
				this.setState({ listaEspecificaciones: [] })
				else 
				this.setState({ listaEspecificaciones: this.state.lista[0].esptecnicas })
				if (this.state.lista[0].imagenes==null)
				this.setState({ listaImagenes: [] })
				else 
				this.setState({ listaImagenes: this.state.lista[0].imagenes })
				this.ObtenerImagen()
				//console.log(this.state.listaCaracteristicas)
				//console.log(this.state.listaEspecificaciones)
				//console.log(this.state.listaImagenes)
				// this.listaProductoDescuento()
			});


	}

	 ObtenerImagen() {
		 let imagenes=this.state.listaImagenes
		//console.log(imagenes)
		let ar=""
		for (var i = 0; i < imagenes.length; i++) {
			ar=imagenes[i].path
			ar = ar.replace('/var/www/html', url.serviceImg)
			//console.log(ar)
			imagenes[i].path=ar
			//console.log(imagenes[i])
		   }
		   //this.state.listaImagenes=imagenes
		   this.setState({ listaImagenes: imagenes })
	}

	

	AgregarCarrito(idproducto,nombre,imagen,precio,stock) {
		if(stock > 0){
		let cadena = [
			{
			  "idprod": idproducto,
			  "nombrep":nombre,
			  "cantidad": 1,
			  "imagen": imagen,
			  "precio":precio		  
			}]

		let ca=[]
		if(localStorage.getItem('CarritoJucumari')){
			ca=JSON.parse(localStorage.getItem('CarritoJucumari'))
			let c=0
			for (var i = 0; i < ca.length; i++) {
				if ((ca[i].idprod) == idproducto) {
					ca[i].cantidad=ca[i].cantidad+1
					c++
				}
			}
			if(c>0){
				localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
				window.dispatchEvent(new Event("storage"));
			}else{
				ca.push(cadena[0])
				localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
				window.dispatchEvent(new Event("storage"));
			}
		}else{ca.push(cadena[0])
			localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
			window.dispatchEvent(new Event("storage"));}
	}else alert("No existe stock disponible")	
	}


	render() {
		return (
			<>		<br />
				<h3 className="text-center"> <MDBBadge color={url.colorprimario}>{this.props.match.params.nombrep}</MDBBadge>
				</h3>
				<MDBContainer>

				<div className="slide-container">
				
					
        <Slide className="text-center">
		{this.state.listaImagenes.map(img => {
         return <div className="each-fade">
			<div className="image-container">
			<img src={img.path} style={{height:400, width: 600}}/>
					
            	
			</div>
          </div>
		  })}
        </Slide>
		
      </div>

				</MDBContainer>
				<MDBContainer>
		<h2 className="text-center"><MDBBadge color="light">BS : {this.props.match.params.preciop}</MDBBadge></h2>
		<p className="text-center">Unidades Disponibles: {this.props.match.params.stockp.replace(".00","")}</p>
				</MDBContainer>
				
				<MDBContainer>

				<MDBBtn color={url.colorprimario} size="lg" block onClick={() => { this.AgregarCarrito(this.props.match.params.idproducto,this.props.match.params.nombrep,this.state.listaImagenes[0].path,this.props.match.params.preciop,this.props.match.params.stockp) }}>AGREGAR AL CARRITO</MDBBtn>
				</MDBContainer>
				<br />
				<MDBContainer>
					<>

						<MDBBtn
							outline color={url.colorprimario} size="lg" block
							onClick={this.toggleCollapse("basicCollapse")}
							style={{ marginBottom: "1rem" }}
						>
							CARACTERÍSTICAS
    					</MDBBtn>
						<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
							{this.state.listaCaracteristicas.map(car => {
								return <MDBListGroup>
									<MDBListGroupItem key={car.idcaracteristicas}>{car.descripcion}</MDBListGroupItem>

								</MDBListGroup>
							})}
						</MDBCollapse>
					</>


				</MDBContainer>
				<MDBContainer>
					<>

						<MDBBtn
							outline color={url.colorprimario} size="lg" block
							onClick={this.toggleCollapse2("basicCollapse")}
							style={{ marginBottom: "1rem" }}
						>
							ESPECIFICACIONES TÉCNICAS
    </MDBBtn>
						<MDBCollapse id="basicCollapse" isOpen={this.state.collapseID2}>
							{
							this.state.listaEspecificaciones.map(esp => {
								return <MDBListGroup>
									<MDBListGroupItem key={esp.idesptecnicas}>{esp.descripcion} <MDBBadge pill color="success">{esp.valor}({esp.unidadmedida})</MDBBadge></MDBListGroupItem>

								</MDBListGroup>
							})}
						</MDBCollapse>
					</>


				</MDBContainer>
				<MDBContainer>
					<br />
					<MDBBreadcrumb light color={url.colorprimario}>
						<MDBBreadcrumbItem iconRegular icon="star">PRODUCTOS RELACIONADOS</MDBBreadcrumbItem>
					</MDBBreadcrumb>
				</MDBContainer>
				<FooterPage />
			</>

			/*
						<MDBContainer>
							<MDBRow>
								<MDBCol size="6">.col-4</MDBCol>
								<MDBCol size="6">.col-4</MDBCol>
							</MDBRow>
			
							<MDBRow>
								<MDBCol size="12">
									<TabDetalleProducto />
								</MDBCol>
							</MDBRow>
							</MDBContainer>
						  */

		)
	}


}

export default DetalleProducto

