import React, { useState } from "react";
import {
  MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBBadge, Link,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCollapseHeader,
  MDBCollapse,
  MDBRow,
  MDBIcon,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";
import url from "../config/Config";
import { React360Viewer } from 'react-360-product-viewer'; 

const ProductoItem = ({ idproducto, nombre, stock, decuento, imagen, precio }) => {
  const [activar, setActivar] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [accordion, setAccordion] = useState(1);
  const [lista, setLista] = useState([]);
  const [url360, seturl360] = useState('');

  function getImage(urlImage) {
    if (!urlImage || urlImage === '') {
      return 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg'
    }
    let ar = "" + urlImage
    ar = ar.replace('/var/www/html', url.serviceImg)
    return ar
  }

  function listaDetalleProducto() {

    let datos = {
      accion: 'detalles',
      llave: url.llavedefault,
      idproducto: idproducto
    }
    fetch(url.service + 'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        setLista(Array.from(responseJson));
        //http://192.168.0.153/empresas/luxhogar/sucursal1/image/377/360/
        let ruta360 = url.serviceImg+'empresas/'+url.empresa+'/'+url.sucursal+'/image/'+idproducto+'/360/'
        seturl360(ruta360);
        setActivar(!activar)
      })
  }


  function ObtenerImagen(pos) {
    let urlimagen = '';
    try {
      urlimagen = getImage(lista[0].imagenes[pos].path)
    } catch (error) {
      urlimagen = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC'
    }
    return urlimagen;
  }


  function masDatos() {
    listaDetalleProducto();
    //console.log(lista)
  }

  function getCaracteristica() {
    let caracteristica = '';
    if (lista[0]) {
      try {

        lista[0].caracteristicas.forEach((element, index, array) => {
          caracteristica += index + 1 + ")    " + element.descripcion + '\n';
        });
      } catch (error) {

      }
    }
    //console.log(caracteristica)
    return caracteristica;
  }

  function getEsptecnicas() {
    let esptecnicas = '';
    if (lista[0]) {
      try {
        lista[0].esptecnicas.forEach((element, index, array) => {
          esptecnicas += index + 1 + ")    " + element.descripcion + '\n';
        });
      } catch (error) {

      }
    }
    //console.log(caracteristica)
    return esptecnicas;
  }


  function AgregarCarrito() {
    if (stock > 0) {
      let cadena = [
        {
          "idprod": idproducto,
          "nombrep": nombre,
          "cantidad": 1,
          "imagen": imagen,
          "precio": precio
        }]

      let ca = []
      if (localStorage.getItem('CarritoJucumari')) {
        ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
        let c = 0
        for (var i = 0; i < ca.length; i++) {
          if ((ca[i].idprod) == idproducto) {
            ca[i].cantidad = ca[i].cantidad + 1
            c++
          }
        }
        if (c > 0) {
          localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
          window.dispatchEvent(new Event("storage"));
         // window.dispatchEvent(new Event("storage"));
        } else {
          ca.push(cadena[0])
          localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
          window.dispatchEvent(new Event("storage"));
          //window.dispatchEvent(new Event("storage"));
        }
      } else {
        ca.push(cadena[0])
        localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
        window.dispatchEvent(new Event("storage"));
       // window.dispatchEvent(new Event("storage"));
      }
    } else setModalAlerta(!modalAlerta);
  }

  return (
    <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
      <MDBModal backdrop={false} isOpen={modalAlerta} toggle={() => {
        setModalAlerta(!modalAlerta);
      }}>
        <MDBModalHeader toggle={() => {
          setModalAlerta(!modalAlerta);
        }}>Producto sin Unidades</MDBModalHeader>
        <MDBModalBody>
          El producto seleccionado no tiene suficientes unidades
          <MDBBtn color='primary' onClick={() => {
            setModalAlerta(!modalAlerta);
          }}>
            Aceptar
          </MDBBtn>
        </MDBModalBody>

      </MDBModal>

      <MDBModal size='lg' isOpen={activar} toggle={() => {
        setActivar(!activar)
      }}>
        <MDBModalBody className='d-flex'>
          <MDBCol size='5' lg='5'>
            {/* Carrousel */}
            <MDBCarousel
              activeItem={1}
              length={3}
              showControls
              showIndicators
              thumbnails
              className='z-depth-1'
            >
              <MDBCarouselInner>
                <MDBCarouselItem itemId='1'>
                  <img
                    className='d-block w-100'
                    src={ObtenerImagen(0)}
                    alt='First slide'
                  />
                </MDBCarouselItem>
                <MDBCarouselItem itemId='2'>
                  <img
                    className='d-block w-100'
                    src={ObtenerImagen(1)}
                    alt='Second slide'
                  />
                </MDBCarouselItem>
                <MDBCarouselItem itemId='3'>
                  <img
                    className='d-block w-100'
                    src={ObtenerImagen(2)}
                    alt='Third slide'
                  />
                </MDBCarouselItem>
              </MDBCarouselInner>
            </MDBCarousel>
          </MDBCol>
          <MDBCol size='7' lg='7'>
            <h2 className='h2-responsive product-name'>
              <strong>{nombre}</strong>
            </h2>
            <h4 className='h4-responsive'>
              {decuento > 0 &&
                <span className='green-text'>
                  <strong>{precio - ((decuento / 100) * precio)}BS</strong>
                </span>
              }
              {decuento > 0 &&
                <span className='grey-text ml-1'>
                  <small>
                    <s>{precio}BS</s>
                  </small>
                </span>
              }
              {decuento == 0 &&
                <span className='green-text'>
                  <strong>{precio}BS</strong>
                </span>
              }
            </h4>
            {/* Accordion wrapper */}
            <div className='my-4'>
              <MDBCard>
                <MDBCollapseHeader onClick={() => {
                  let state;
                  let number = 1;
                  if (accordion !== number) {
                    state = number;
                  } else {
                    state = false;
                  }
                  setAccordion(state);
                }}>
                  Caracteristicas
                  <i
                    className={`ml-1 ${accordion === 1
                        ? 'fa fa-angle-down rotate-icon'
                        : 'fa fa-angle-down'
                      }`}
                  />
                </MDBCollapseHeader>
                <MDBCollapse isOpen={accordion === 1}>
                  <MDBCardBody type="text">
                    <p align="left"> {getCaracteristica()}</p>
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
              <MDBCard>
                <MDBCollapseHeader onClick={() => {
                  let state;
                  let number = 2;
                  if (accordion !== number) {
                    state = number;
                  } else {
                    state = false;
                  }
                  setAccordion(state)
                }}>
                  Especificaciones Tecnicas
                  <i
                    className={`ml-1 ${accordion === 2
                        ? 'fa fa-angle-down rotate-icon'
                        : 'fa fa-angle-down'
                      }`}
                  />
                </MDBCollapseHeader>
                <MDBCollapse isOpen={accordion === 2}>
                  <MDBCardBody>
                    {getEsptecnicas()}
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
                <React360Viewer 
                  imagesBaseUrl={url360}
                  imagesCount={30}
                  imagesFiletype="jpg"
                  mouseDragSpeed={20}
                  autoplaySpeed={35}
                  autoplay='true'
                  showRotationIconOnStartup={true}
                >
                </React360Viewer>
            </div>

            
            <MDBRow className='justify-content-center'>
              <MDBBtn
                color='secondary'
                className='ml-4'
                onClick={() => {
                  setActivar(!activar);
                }}
              >
                Cerrar
              </MDBBtn>
              <MDBBtn
                color='primary'
                className='ml-4'
                onClick={() => {
                  AgregarCarrito();
                  setActivar(!activar);
                }}
              >
                Agregar al Carrito
                <MDBIcon icon='cart-plus' className='ml-2' />
              </MDBBtn>
            </MDBRow>
          </MDBCol>
        </MDBModalBody>
      </MDBModal>


      <MDBCard className="align-items-center" onClick={() => {
        //	setActivar(!activar);
        masDatos()
      }}>
        <h6 style={{ color: '#ffffff' }}>.</h6>
        <h6>
          <strong>
            <a className="dark-grey-text">
              {nombre}

            </a>
          </strong>
        </h6>
        <MDBCardImage
          src={getImage(imagen)}
          top
          alt="sample photo"
          overlay="white-slight"
          style={{ height: '150px' }}
        />
        <MDBCardBody className="text-center">
          <a className="grey-text">
            {stock.replace(".00", "")} Unidades
          </a>

          <h4 className="font-weight-bold blue-text">
            {decuento > 0 &&
              <span className='grey-text ml-1'>
                <small>
                  <s>{precio} BS </s>
                </small>
              </span>
            }
            <br></br>
            {decuento > 0 &&
              <MDBBadge pill color={url.colorllamativo}>
                {precio - ((decuento / 100) * precio)} Bs
              </MDBBadge>
            }
            {decuento == 0 &&
              <MDBBadge pill color={url.colorllamativo}>
                {precio} Bs
              </MDBBadge>
            }
            <strong></strong>
          </h4>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>





  );
}

export default ProductoItem;

