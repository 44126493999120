import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import url from  '../components/config/Config'
class FooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      llave: url.llavedefault
    }

    this.listaFooter()

  }

  listaFooter() {
    let datos = {
      accion: 'getEmpresa',
      llave: this.state.llave
    }
    fetch(url.service+'empresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        this.setState({ lista: Array.from(responseJson) });
        //console.log(this.state.lista)
      });
  }
render() {
  return (
	
    <MDBFooter color={url.colorprimario} className="font-small pt-4 mt-4">
	  
	  <MDBContainer>
      {this.state.lista.map(post=>{
        return(
          <div key={post.id}>
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
        <h5 className="title">{post.nombre}</h5>
            <p>
              Teléfono:   {post.telefono}
            </p>
          </MDBCol>
          <MDBCol md="6">
            <h5 className="title">Ubicación</h5>
            <ul>
              <li className="list-unstyled">
                <a>País: {post.pais}</a>
              </li>
              <li className="list-unstyled">
                <a>Ciudad: {post.ciudad}</a>
              </li>
              <li className="list-unstyled">
                <a>Dirección: {post.direccion}</a>
              </li>
              
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
       </div> )
})}
</MDBContainer>
<div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.jucumari.bo/"> Jucumari </a>
        </MDBContainer>
      </div>

    </MDBFooter>
  );
}
}
export default FooterPage;