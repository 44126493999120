import React from "react";
import { MDBTabContent,MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane,MDBRow, MDBCol,MDBListGroup,MDBListGroupItem} from "mdbreact";
import '../components/styles/NavBar.css'
import FooterPage from "../components/FooterPage";
import url from  '../components/config/Config'
class Pedido extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemOuterTabs: "1",
      lista :[] ,
      llave : '' , 
      correo : '' , 
      idcliente : '' ,
      listaPedido : [] ,
      listaDetallePedido : [] ,
      detalleidpedido:0,
      detallefecha:'',
      detalleestado:'',
      detalletotal:'',
    }
    if(localStorage.getItem('LoginJucumari')){
      let l=JSON.parse(localStorage.getItem('LoginJucumari'))
      if(l.length>0){
      this.state.llave=l[0].llave
      this.state.correo=l[0].correo
      this.state.idcliente=l[0].idcliente
      }
    }
    this.listarPedidos()
  }


  
  listarPedidos() {
    //console.log("URL : "+url.service+'pedido')
    let datos = {
      accion: 'pedidoCliente',
      llave: this.state.llave,
      idcliente: this.state.idcliente
    }
    //console.log(datos)
    fetch(url.service+'pedido', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        this.setState({ listaPedido: Array.from(responseJson) });
        //console.log(this.state.listaPedido)
      });
  }
  toggleOuterTabs = tab => e => {
    if (this.state.activeItemOuterTabs !== tab) {
      this.setState({
        activeItemOuterTabs: tab
      });
    }
  };



  verDetallePedido(idventa , idpedido,fecha,estado,total){
    this.setState({ detalleidpedido: idpedido})
    this.setState({ detallefecha: fecha })
    this.setState({ detalleestado: estado })
    this.setState({ detalletotal: total })
    let datos1 = {
      accion: 'ListarDetalleVenta',
      llave: this.state.llave,
      idventa: idventa
    }
    fetch(url.service+'detalleVenta', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos1) }).then((response) =>
      response.json()).then((responseJson) => {
        this.setState({ listaDetallePedido: Array.from(responseJson) });
      });

    
  }

render() {
    return (
      <div style={{marginTop : 75}}>
    
      <p  className='h1-responsive font-weight-bold text-center my-5'>
        Lista de Pedidos.
      </p>
      <MDBContainer>
      <MDBNav tabs className="nav-justified" color={url.colorprimario}>
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItemOuterTabs === "1"} onClick={this.toggleOuterTabs("1")} role="tab" >
               Pedidos
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItemOuterTabs === "2"} onClick={this.toggleOuterTabs("2")} role="tab" >
              Detalle
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>

        <MDBTabContent
          className="card mb-5"
          activeItem={this.state.activeItemOuterTabs}
        >
          <MDBTabPane tabId="1" role="tabpanel">
          <MDBContainer>
          <MDBListGroup >
                {this.state.listaPedido.map((item, key)=>{
                          return(
                            
                          <MDBListGroupItem onClick={()=>{
                            this.setState({activeItemOuterTabs : '2'})
                            this.verDetallePedido(item.idventa ,item.idpedido,item.fecha,item.estado,item.total )
                            
                          }}>
                              <a>Numero de Pedido {item.idpedido}</a><br/>
                              <a class="text-muted">{item.fecha}</a> <br/>
                              <a class="text-muted"> {item.total} </a> <br/>
                              <a className='green-text'>{item.estado} </a><br/>
                              
                            </MDBListGroupItem>
                          );
                      })}
          </MDBListGroup>
        </MDBContainer>
          </MDBTabPane>


          <MDBTabPane tabId="2" role="tabpanel">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h5>Pedido</h5>
                    <a>NUMERO DE PEDIDO : {this.state.detalleidpedido}</a><br/>
                    <a>Fecha : <a class="text-muted">{this.state.detallefecha}</a></a><br/>
                    <a>Estado : <a className='green-text'>{this.state.detalleestado}</a></a><br/>
                    <a>Total : <a class="text-muted">{this.state.detalletotal}</a></a><br/>
              </MDBCol>
            </MDBRow>
            <br/>
                <h5>Detalle de Pedido</h5>
              
          <MDBListGroup >
                {this.state.listaDetallePedido.map((item, key)=>{
                          return(
                          <MDBListGroupItem >
                              <a>{item.producto}</a><br/>
                              <a>cantidad : <a class="text-muted">{item.cantidad}</a></a><br/>
                              <a>Precio por Unidad : <a class="text-muted">{item.precio}</a></a><br/>
                              <a>Total : <a class="text-muted">{item.total}</a></a><br/>
                            </MDBListGroupItem>
                          );
                      })}
          </MDBListGroup>
           </MDBContainer>
          </MDBTabPane>
        </MDBTabContent>
    </MDBContainer>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
  <br></br>
<FooterPage />
    </div>
   
    )
  }
}
export default Pedido