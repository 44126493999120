import React from "react";
import { MDBContainer, MDBListGroup, MDBListGroupItem, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBCard, MDBCardBody, MDBInput, MDBModalBody, MDBModal, MDBModalHeader, MDBModalFooter } from "mdbreact";
import FooterPage from '../components/FooterPage'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import url from '../components/config/Config';
import { withRouter } from 'react-router-dom';
const containerStyle = {
  width: '650px',
  height: '400px'
};


/*const center = {
  lat: -17.7662398,
  lng: -63.1601249
};*/

class RealizarPedido extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      formActivePanel3: 1,
      formActivePanel1Changed: false,
      center: {
        lat: -17.7662398,
        lng: -63.1601249
      },
      direccion: '',
      nombref: '',
      nitci: '',
      telefono: '',
      total: 0,
      cantidad: 0,
      formapago: 'ContraEntrega',
      carrito: [],
      llave: '',
      correo: '',
      idcliente: 0,
      car: [],
      vent: [],
      modalAlerta: false,
      mensajeAlerta: '',
      tituloMensaje: 'Error de Datos',
      finalizar: false , 
      empresa : undefined
    }
     this.getEmpresa();
    if (localStorage.getItem('LoginJucumari')) {

    } else {
      alert("Inicie Sesion.")
      window.history.back()
    }
    this.getLocation()
    if (localStorage.getItem('CarritoJucumari')) {
      let ca = JSON.parse(localStorage.getItem('CarritoJucumari'))
      this.state.carrito = ca

      let t = this.state.carrito
      let y = []
      let can = 0
      for (var i = 0; i < t.length; i++) {
        let x = {
          "idproducto": parseInt(t[i].idprod),
          "cantidad": t[i].cantidad,
          "producto": {
            "imagen": ""
          }
        }
        y.push(x)
        //console.log(y)
      }
      this.state.car = y
    } else {
      alert("Carrito Vacío.")
      window.history.back()
    }
    this.CantidadTotal()
    //window.location.reload(true)
    if (localStorage.getItem('LoginJucumari')) {
      let l = JSON.parse(localStorage.getItem('LoginJucumari'))
      if (l.length > 0) {
        this.state.llave = l[0].llave
        this.state.correo = l[0].correo
        this.state.idcliente = l[0].idcliente
        this.state.direccion = l[0].direccion
        this.state.telefono = l[0].telefono
      }
    }
    
  }

  

  CantidadTotal() {
    let t = this.state.carrito
    let tot = 0
    let can = 0
    for (var i = 0; i < t.length; i++) {
      tot = tot + (t[i].cantidad * t[i].precio)
      can = can + t[i].cantidad
    }
    this.state.total = tot
    this.state.cantidad = can
  }


  getLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        const coords = pos.coords;
        //this.setState({center[0].lat:coords.latitude})
        this.state.center.lat = coords.latitude
        this.state.center.lng = coords.longitude
        //console.log(coords.latitude,coords.longitude)

      });

    }
    //window.dispatchEvent(new Event("storage"));
  }
  swapFormActive = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  handleNextPrevClick = (a) => (param) => (e) => {
    if (param == 1 || param == 4) {

      this.setState({
        ['formActivePanel' + a]: param,
        ['formActivePanel' + a + 'Changed']: true
      });

    }
    if (param == 2) {
      if (this.state.direccion.length > 0) {
        this.setState({
          ['formActivePanel' + a]: param,
          ['formActivePanel' + a + 'Changed']: true
        });
      } else {
        this.setState({ mensajeAlerta: 'Debe llenar La Direccion' });
        this.setState({ modalAlerta: !this.state.modalAlerta })
      }
    }

    if (param == 3) {
      if (this.state.telefono.length > 0) {
        let check = document.getElementById("checkbox1")

        try {
          if (check.checked == false) {
            if (this.state.nitci.length > 0 && this.state.nombref.length > 0) {
              this.setState({
                ['formActivePanel' + a]: param,
                ['formActivePanel' + a + 'Changed']: true
              });
            } else {
              this.setState({ mensajeAlerta: 'Debe llenar De CI y Nombre' });
              this.setState({ modalAlerta: !this.state.modalAlerta })
            }
          } else {
            this.setState({
              ['formActivePanel' + a]: param,
              ['formActivePanel' + a + 'Changed']: true
            });
          }
        } catch (error) {
          this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
          });
        }
      } else {
        this.setState({ mensajeAlerta: 'Debe llenar Telefono' });
        this.setState({ modalAlerta: !this.state.modalAlerta })
      }
    }
  }


  calculateAutofocus = (a) => {
    if (this.state['formActivePanel' + a + 'Changed']) {
      return true
    }
  }


  getEmpresa(){
    let datos = {
      accion: 'getEmpresa',
      llave: url.llavedefault
    }
    fetch(url.service+'empresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        this.setState({empresa : responseJson[0]})
        //console.log('====================================');
        //console.log(this.state.empresa);
        //console.log('====================================');
      });
  }

  onDragEnd = (e) => {
    this.state.center.lat = e.latLng.lat();
    this.state.center.lng = e.latLng.lng();
    //console.log(this.state.center)
  }
  ObtenerDireccion(e) {
    this.setState({ direccion: e.target.value });
  }
  ObtenerNombre(e) {
    this.setState({ nombref: e.target.value });
  }
  ObtenerNit(e) {
    this.setState({ nitci: e.target.value });
  }
  ObtenerTelefono(e) {
    this.setState({ telefono: e.target.value });
  }

  verificarFact = () => {
    let check = document.getElementById("checkbox1")
    if (check.checked == true) {
      this.state.nombref = "S/N"
      this.state.nitci = "0"
      document.getElementById("txtnombre").disabled = true
      document.getElementById("txtnit").disabled = true
      //console.log(this.state.nombref,this.state.nitci)
    } else {
      this.state.nombref = ""
      this.state.nitci = ""
      document.getElementById("txtnombre").disabled = false
      document.getElementById("txtnit").disabled = false
      //console.log(this.state.nombref,this.state.nitci)
    }
  }

  Enviar = () => {
    //console.log('enviar ')
    if ((this.state.telefono != '') && (this.state.direccion != '')) {
      let venta = {
        "llave": this.state.llave,
        "accion": "agregarVentaOnline",
        "carrito": this.state.car,
        "identificador": this.state.formapago,
        "costoEnvio": 15,
        "cliente": {
          "ci": "",
          "correo": this.state.correo,
          "direccion": this.state.direccion,
          "estado": "1",
          "idcliente": this.state.idcliente,
          "imagen": "",
          "latitud": "",
          "longitud": "",
          "nit": this.state.nitci,
          "nombre": this.state.nombref,
          "obsv": "",
          "pass": "",
          "recuperarpass": "",
          "sexo": "",
          "telefono": this.state.telefono
        },
        "ruta": {
          "longitud": this.state.center.lat,
          "latitud": this.state.center.lng
        },
        idlistaprecio : this.state.empresa.idlistaprecios,
        idalmacen : this.state.empresa.idalmacen

      }
      //console.log(venta)
      fetch(url.service + 'ventaNP', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(venta) }).then((response) =>
        response.json()).then((responseJson) => {
          let v = responseJson
          //console.log(v.payment_url)
          //console.log(v.venta)
          if (v.payment_url == undefined) {
            alert(v.venta)
            const { history } = this.props;
            history.push("/Carrito"+'?e='.concat(localStorage.getItem('empresa')));
           // window.location.assign("/Carrito"+'?e='.concat(localStorage.getItem('empresa')))
          } else {
            localStorage.removeItem('CarritoJucumari')
            window.dispatchEvent(new Event("storage"));
            this.setState({ finalizar: true });
            this.setState({ tituloMensaje: 'Exito' });
            this.setState({ mensajeAlerta: 'Pedido Realizado con Exito' });
            this.setState({ modalAlerta: !this.state.modalAlerta })
          }
        });

    } else {
      alert("Los campos Direccion y teléfono son obligatorios.")
    }

  }


  render() {
    const { history } = this.props;
    return (<div className="md-form mb-0">
      <MDBContainer>

        <br />
        <br />
        <br />
        <h2 className="h1-responsive font-weight-bold text-center">
          Finalizar Compra
        </h2>
        <MDBModal backdrop={false} isOpen={this.state.modalAlerta} toggle={() => {
          this.setState({ modalAlerta: !this.state.modalAlerta })
          if (this.state.finalizar) {
            //history.push("/" + '?e='.concat(localStorage.getItem('empresa')));
             window.location.assign("/"+'?e='.concat(localStorage.getItem('empresa')))
          }
        }}>
          <MDBModalHeader toggle={() => {
            this.setState({ modalAlerta: !this.state.modalAlerta })
            if (this.state.finalizar) {
             window.location.assign("/"+'?e='.concat(localStorage.getItem('empresa')))
             //history.push("/" + '?e='.concat(localStorage.getItem('empresa')));
            }
          }}>{this.state.tituloMensaje}</MDBModalHeader>
          <MDBModalBody>
            {this.state.mensajeAlerta}

          </MDBModalBody>
          <MDBBtn color='primary' onClick={() => {
            this.setState({ modalAlerta: !this.state.modalAlerta })
            if (this.state.finalizar) {
              window.location.assign("/"+'?e='.concat(localStorage.getItem('empresa')))
              //history.push("/" + '?e='.concat(localStorage.getItem('empresa')));
            }
          }}>
            Aceptar
          </MDBBtn>
        </MDBModal>
        <MDBCard>
          <MDBCardBody>
            <MDBRow className="pt-5 justify-content-center">
              <MDBCol md="2" className="pl-5 pl-md-0 pb-5">
                <MDBStepper icon vertical>
                  <MDBStep icon="map-marked-alt" stepName="Dirección de Entrega" onClick={this.swapFormActive(3)(1)} vertical />
                  <MDBStep icon="file-alt" stepName="Facturación" onClick={this.swapFormActive(3)(2)} vertical />
                  <MDBStep far icon="credit-card" stepName="Formas de Pago" onClick={this.swapFormActive(3)(3)} vertical />
                  <MDBStep icon="check" stepName="Resumen del Pedido" onClick={this.swapFormActive(3)(4)} vertical />
                </MDBStepper>
              </MDBCol>

              <MDBCol md="7">
                {this.state.formActivePanel3 === 1 && (
                  <MDBCol md="12">
                    <h3 className="font-weight-bold pl-0 my-4">
                      <strong>Dirección de Entrega</strong>
                    </h3>
                    <LoadScript
                      googleMapsApiKey={url.llavemapa}
                    >
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={this.state.center}
                        zoom={15}
                      ><Marker
                          title="Mi Ubicación"
                          id={1}
                          position={this.state.center}
                          draggable={true}
                          onDragEnd={(e) => this.onDragEnd(e)}
                        />

                        { /* Child components, such as markers, info windows, etc. */}
                        <></>
                      </GoogleMap>
                    </LoadScript>

                    <MDBInput label="Detalle de dirección" icon="home" onChange={e => this.ObtenerDireccion(e)} value={this.state.direccion} />
                    <MDBBtn color={url.colorprimario} type='submit' rounded className="float-right" onClick={this.handleNextPrevClick(3)(2)}>
                      SIGUIENTE
                    </MDBBtn>
                  </MDBCol>
                )}
                {this.state.formActivePanel3 === 2 && (
                  <MDBCol md="12">
                    <h3 className="font-weight-bold pl-0 my-4">
                      <strong>Datos para Facturación</strong>
                    </h3>
                    <MDBInput
                      label={
                        <>
                          &nbsp;
                          <a className={url.colorprimario + 'text'}>
                            Sin Nit / CI
                          </a>
                        </>
                      }
                      type='checkbox'
                      id='checkbox1'
                      onClick={this.verificarFact}
                    />
                    <MDBInput label="Nombre" className="mt-3" id="txtnombre" autoFocus={this.calculateAutofocus(3)} onChange={e => this.ObtenerNombre(e)} value={this.state.nombref} />
                    <MDBInput label="NIT/CI" className="mt-3" id="txtnit" onChange={e => this.ObtenerNit(e)} value={this.state.nitci} />
                    <MDBInput label="Teléfono/Celular" className="mt-3" type="number" onChange={e => this.ObtenerTelefono(e)} value={this.state.telefono} />
                    <MDBBtn color={url.colorprimario} rounded className="float-left" onClick={this.handleNextPrevClick(3)(1)}>
                      ANTERIOR
                    </MDBBtn>
                    <MDBBtn color={url.colorprimario} rounded className="float-right" onClick={this.handleNextPrevClick(3)(3)}>
                      SIGUIENTE
                    </MDBBtn>
                  </MDBCol>
                )}
                {this.state.formActivePanel3 === 3 && (
                  <MDBCol md="12">
                    <h3 className="font-weight-bold pl-0 my-4">
                      <strong>Formas de Pago</strong>
                    </h3>
                    <MDBInput label="Pago ContraEntrega" type="checkbox" id="checkbox3" checked autoFocus={this.calculateAutofocus(3)} />

                    <br />
                    <MDBBtn color={url.colorprimario} rounded className="float-left" onClick={this.handleNextPrevClick(3)(2)}>
                      ANTERIOR
                    </MDBBtn>
                    <MDBBtn color={url.colorprimario} rounded className="float-right" onClick={this.handleNextPrevClick(3)(4)}>
                      SIGUIENTE
                    </MDBBtn>
                  </MDBCol>
                )}
                {this.state.formActivePanel3 === 4 && (
                  <MDBCol md="12">
                    <h3 className="font-weight-bold pl-0 my-4">
                      <strong>Resumen del Pedido</strong>
                    </h3>
                    <MDBContainer>
                      <MDBListGroup>
                        <MDBListGroupItem>
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Pedido</h5>
                          </div>
                          <p className="mb-1">Cantidad de articulos: {this.state.cantidad}</p>
                          <p className="mb-1">Total a pagar: {this.state.total}</p>
                        </MDBListGroupItem>
                        <MDBListGroupItem>
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Dirección de Entrega</h5>
                          </div>
                          <p className="mb-1">{this.state.direccion}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem>
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Datos Facturacion</h5>
                          </div>
                          <p className="mb-1">{this.state.nombref}</p>
                          <p className="mb-1">{this.state.nitci}</p>

                        </MDBListGroupItem>
                        <MDBListGroupItem>
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Forma de Pago</h5>
                          </div>
                          <p className="mb-1">{this.state.formapago}</p>

                        </MDBListGroupItem>
                      </MDBListGroup>
                    </MDBContainer>

                    <MDBBtn color={url.colorprimario} rounded className="float-left" onClick={this.handleNextPrevClick(3)(3)}>
                      ANTERIOR
                    </MDBBtn>
                    <MDBBtn color="success" rounded className="float-right" onClick={this.Enviar}>
                      FINALIZAR
                    </MDBBtn>
                  </MDBCol>
                )}
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
      </MDBContainer>

      <FooterPage />
    </div>
    );
  };
}

export default withRouter(RealizarPedido);