import React, { useState, useEffect } from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from "mdbreact";
import url from '../components/config/Config';

const CarouselPage = ({ listaBanner = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => 
        prevIndex === listaBanner.length ? 1 : prevIndex + 1
      );
    }, 1000); // Cambia cada 3 segundos

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, [listaBanner.length]);

  return (
    <MDBContainer>
      <MDBCarousel 
        activeItem={currentIndex}
        length={listaBanner.length}
        showControls={true}
        showIndicators={true}
      >
        <MDBCarouselInner>
          {listaBanner.map((ban, index) => (
            <MDBCarouselItem key={index + 1} itemId={index + 1}>
              <MDBView>
                <img
                  className="d-block w-100"
                  src={ban.path.replace('/var/www/html', url.serviceImg)}
                  alt={`Slide ${index + 1}`}
                  style={{ height: '50vh' }}
                />
              </MDBView>
            </MDBCarouselItem>
          ))}
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  );
}

export default CarouselPage;
