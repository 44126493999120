import React from "react";
import { MDBRow } from "mdbreact";
import ProductoItem from './ProductoItem'
const ProductosListItem = ({ listaProductos, id }) => {
  
	return (


		<section className="text-center my-5">

			<MDBRow>
				{
					listaProductos.map((prod, index) => {
						if (prod.nombre == undefined)
							return
						return <ProductoItem idproducto={prod.idproducto} nombre={prod.nombre} stock={prod.stock} decuento={prod.decuento} imagen={prod.imagen} precio={prod.precio} />
					})
				}
			</MDBRow>


		</section>


	);
}

export default ProductosListItem;
