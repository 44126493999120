import AdSense from 'react-adsense';
import React, { Component } from 'react';
import './AdsenseStylesheet.css';

class Publicidad extends Component {
    render() {
        return (
            <AdSense.Google
                client='ca-pub-3904125466623659'
                slot='9310344078'
                style={{ display: 'block' }}
                format='auto'
                />
        );
    }
}

export default Publicidad;