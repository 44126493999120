import React from 'react'
//import useFetch from '../hooks/useFetch'
//import url from '../config'

//import NavbarPage from '../components/NavbarPage'
import FooterPage from '../components/FooterPage'
//import { Link } from "react-router-dom"
import url from  '../components/config/Config'
import { MDBContainer,MDBBadge} from "mdbreact";

import ListaProductos from '../components/productos/ListaProductos'

class Categoria extends React.Component {



	constructor(props) {
		super(props);

		//let bookId = this.props.match.params // aqui vienen los parametros
  		// let book = FindBookById(bookId)
		//console.log("PARAMETRO URL : "+bookId.bookId)
		// this.setState({ idCategoria: bookId.bookId});
		// const querystring = window.location.search
		// const params = new URLSearchParams(querystring)
		// console.log(params.get('id'))
		this.state = {
			listaProductos: [],
			llave: url.llavedefault
		}
		
	}


	listaProductosPorCategoria(id) {

		let datos = {
			accion: this.props.match.params.tipo == 1 ? 'listarPorCategoria' : 'listarPorBusqueda',
			llave: this.state.llave,
			idcategoria :this.props.match.params.tipo == 1 ?  id : this.props.match.params.nombrec
		}
		fetch(url.service+'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
			response.json()).then((responseJson) => {
				this.setState({ listaProductos: Array.from(responseJson) });
				// this.listaProductoDescuento()
				//console.log(this.state.listaProductos)
		});

		
	}

	listaProductos4(id) {
		//console.log( this.props.match.params)
		this.listaProductosPorCategoria(id)
		//console.log(this.state.listaProductos.length);
		let item = ["","","",""];
		let listaGral = []
		let acu = 1
		this.state.listaProductos.map((prod,i) => {
			//console.log(prod+" "+i)
			item[i % 4] = prod
			if (i % 4 == 3) {
				listaGral.push({ datos: item, i: acu });
				acu++
				item = ["","","",""];
			}
		})

		if (this.state.listaProductos.length % 4 != 4) { 
			listaGral.push({ datos: item, i: acu });
			//console.log("ËNTRO CON MENOS DE 4 prod")
		}
		 //console.log("ENTROP OTRA VEZ : "+this.props.match.params.bookId)
		 //console.log(this.state.listaProductos.length)

		return listaGral	
		
	}



	render() {
		return (<div><br/>
			<fragment>
				
				
				<MDBContainer>
					
				<h3 className="text-center"> <MDBBadge color={url.colorprimario}>{this.props.match.params.nombrec}</MDBBadge>
  				</h3>				
				<ListaProductos listaProductos = {this.listaProductos4(this.props.match.params.bookId)} />
			
				</MDBContainer>
		<FooterPage />
	</fragment>
			</div>
		)
	}


}

export default Categoria



