//import React, { Component } from 'react';
//// import { render } from 'react-dom';
//import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
//import {
//	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
//	MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer,NavLink
//} from "mdbreact";
//
//import NotFound from '../pages/NotFound'
//import Main from '../pages/Main'
//import Categoria from '../pages/Categoria'
//import DetalleProducto from '../pages/DetalleProducto'
//
//
//import NavbarPage from '../components/NavbarPage'
//import FooterPage from '../components/FooterPage'
//
//
//class App extends Component {
//
//	constructor(props) {
//		super(props);
//		this.state = {
//			lista: [],
//			llave: 'mksxpc7xle1n835t0o06clozz76jf5'
//		}
//
//		this.listaCategoria()
//
//	}
//
//	listaCategoria() {
//		let datos = {
//			accion: 'listarCategoria',
//			llave: this.state.llave
//		}
//		fetch('http://dev.service.jucumari.bo/categoria', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
//			response.json()).then((responseJson) => {
//				this.setState({ lista: Array.from(responseJson) });
//				console.log(this.state.lista)
//			});
//	}
//
//
//	render() {
//		return (
//			<>
//				
//				<Router>
//				<NavbarPage categorias ={this.state.lista} />
//
//					
//					<Switch>
// 							<Route  path="/" component={Main} exact/>
// 							<Route  path="/categoria/:bookId" component={Categoria} exact/>
//							 <Route  path="/producto" component={DetalleProducto} exact/>
//							<Route component={NotFound} />
//					</Switch>
//
//					<FooterPage />
//
//				</Router>
//			</>
//		);
//	}
//}
//
//export default App


import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from '../pages/NotFound'
import Main from '../pages/Main2'
import Categoria from '../pages/Categoria'
import DetalleProducto from '../pages/DetalleProducto'

import NavbarPage from '../components/NavbarPage'

import Contacto from '../pages/Contacto'
import Carrito from '../pages/Carrito'
import RealizarPedido from '../pages/RealizarPedido';
import Login from '../pages/Login';
import Pedido from '../pages/Pedido';
import url from '../components/config/Config'
import Perfil from '../pages/Perfil';
import Mas from '../pages/Mas';
import PerfilEmpresa from '../pages/PerfilEmpresa';

import ReactGA from 'react-ga4';
import AdSense from "./AdSense";


class App extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			lista: [],
			listaBanner: [],
			listaCategoriaItems:[],
			listaAllProducts:[],
			load: false
		}
		this.init();
		
	}

	async init() {
		const response = await fetch('/config.json');
		const data = await response.json();

		if (data.APP_NAME) {
			localStorage.setItem('empresa', data.APP_NAME);
			url.empresa = localStorage.getItem('empresa')
			this.login()
		} else {
			if (window.location.href.split('=')[1] == undefined) {
				url.empresa = localStorage.getItem('empresa')
				this.login()
			} else {
				url.empresa = window.location.href.split('=')[1];
				localStorage.setItem('empresa', window.location.href.split('=')[1]);
				this.login()
			}
		}
		ReactGA.initialize('G-W0XQYLYRHZ');
		if (ReactGA.isInitialized) {
			ReactGA.send({ hitType: "Principal", page: window.location.pathname })
		} else {
		}
	}

	async  getImgBanner () {
		const datos = {
		  accion: 'banner',
		  llave: url.llavedefault,
		};
  
		fetch(url.service + 'empresa', {
		  method: 'POST',
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(datos),
		})
		  .then((response) => response.json())
		  .then((responseJson) => {
			if (responseJson && responseJson.length > 0) {
			  const banners = responseJson[0].empresa_banner.map((banner) => ({
				id: banner.id,
				path: banner.path.replace('/var/www/html', url.serviceImg), 
			  }));
			  this.setState({ listaBanner: banners });
			}
		  })
		  .catch((error) => {
			console.error('Error al obtener datos:', error);
		  });
	  };

	obtenerCategoriaItems (){
		let datos = {
			accion: 'listarCategoriaItems',
			llave: url.llavedefault
		}
		fetch(url.service + 'categoria', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) })
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({ listaCategoriaItems : Array.from(responseJson) });
			}).catch(error => {
				console.error('Error al obtener datos:', error);
			});
	}

	getAllProducts(){
		let datos = {
			accion: 'listarPorCategoria',
			llave: url.llavedefault,
			idcategoria: 0
		}
		fetch(url.service + 'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) })
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({ listaAllProducts : Array.from(responseJson) });
			}).catch(error => {
				console.error('Error al obtener datos:', error);
			});
	}

	login() {
		let datos = {
			correo: url.userDefault,
			pass: url.correoDefault,
			empresa: url.empresa,
			sucursal: url.sucursal,
		}
		fetch(url.service + 'loginClienteEmpresa', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
			response.json()).then((responseJson) => {
				let lista = Array.from(responseJson);
				let params = {
					e: lista[0].label.split('-')[0],
					s: lista[0].label.split('-')[1],
					d: '12346-7888',
					id: lista[0].id[0].idcliente
				};
				fetch(url.service + 'jucumariCliente', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(params) }).then((response) =>
					response.json()).then((responseJson) => {
						if (responseJson.llave) {
							url.llavedefault = responseJson.llave;
							this.listaCategoria();
							this.getImgBanner();
							this.obtenerCategoriaItems();
							this.getAllProducts();
						}
					})
			});
	}

	listaCategoria() {
		let datos = {
			accion: 'listarCategoria',
			llave: this.state.llave
		}
		fetch(url.service + 'categoria', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
			response.json()).then((responseJson) => {
				this.setState({ lista: responseJson });
				this.setState({ load: true })
			});
	}




	render() {
		if (this.state.load) {
			return (
				<div style={{ backgroundColor: '#f3f4f6' }}>
					<Router>
						<NavbarPage />
						<AdSense />
						<div style={{height:'80px'}}></div>
						<Switch>
							<Route path="/" exact render={() => <Main listaBanner={this.state.listaBanner} listaCategoriasItems={this.state.listaCategoriaItems} listaAllProductos={this.state.listaAllProducts}/>} />							
							<Route path="/categoria/:bookId/:nombrec/:tipo" component={Categoria} exact />
							<Route path="/detalleproducto/:idproducto/:nombrep/:preciop/:stockp" component={DetalleProducto} exact />
							<Route path="/Contacto" component={Contacto} exact />
							<Route path="/Carrito" component={Carrito} exact />
							<Route path="/RealizarPedido" component={RealizarPedido} exac />
							<Route path="/Login" component={Login} exac />
							<Route path="/Pedido" component={Pedido} exac />
							<Route path="/Perfil" component={Perfil} exac />
							<Route path="/Mas" component={Mas} exac />
							<Route path="/PerfilEmpresa" component={PerfilEmpresa} exac />
							<Route component={NotFound} />
						</Switch>

					</Router>
				</div>

			)
		} else {
			return (
				<div id="mdb-preloader" className="flex-center" style={{ backgroundColor: 'white' }} >
					<div className="preloader-wrapper active">
						<div className="spinner-layer spinner-blue-only">
							<div className="circle-clipper left">
								<div className="circle"></div>
							</div>
							<div className="gap-patch">
								<div className="circle"></div>
							</div>
							<div className="circle-clipper right">
								<div className="circle"></div>
							</div>
						</div>
					</div>
				</div>

			);
		}
	}


}

export default App


// // render(App, document.getElementById("root"))

// // export default App