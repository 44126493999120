import React, { useState } from "react";
import {MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardFooter,
  MDBTooltip,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,MDBBadge,
  MDBModal,MDBModalBody,MDBCollapseHeader,MDBCollapse,
  MDBBtn ,MDBIcon,MDBModalHeader  
} from "mdbreact";
import url from '../components/config/Config'
import SectionContainer from '../components/sectionContainer';



const Productos = ({tipo , titulo}) => {

  const [listap, setListap] = useState([]);
  const [contador, setContador] = useState(0);

  if (contador == 0)
    obtenerDatos();

  function obtenerDatos() {
    let datos = {
      accion: tipo,
      llave: url.llavedefault
    }
    fetch(url.service + 'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
      response.json()).then((responseJson) => {
        setListap(responseJson)
        setContador(1)
      });

  }

  function listaIndex(listaprueba) {
    //console.log(listaprueba)
		let item = ["", "", ""];
		let listaGral = []
		let acu = 1
		try {
      listaprueba.map((prod, i) => {
        item[i % 3] = prod
        if (i % 3 == 2) {
          listaGral.push({ datos: item, i: acu });
          acu++
          item = ["", "", ""];
        }
      })
    } catch (error) {
      
    }

		/*if (listaprueba.length % 3 != 2) {
			listaGral.push({ datos: item, i: acu });
		}*/
    if (listaprueba.length <= 2 && listaprueba.length >0) {
			if (listaprueba.length == 1) {
        item = [""]
				item[0] = listaprueba[0];
				listaGral.push({ datos: item, i: acu });
			} else {
        item = ["", ""]
				item[0] = listaprueba[0];
				item[1] = listaprueba[1];
				listaGral.push({ datos: item, i: acu });
			}
		} 
		return listaGral;
	}


  const ItemCarouselList = ({ listaCategorias, id }) => {
    
    return (
      <MDBCarouselItem key={id} itemId={id}>
                {
                listaCategorias.map((cat, index) => {
                  return <ItemCarousel key={index} idproducto={cat.idproducto} imagen={cat.path} nombre={cat.nombre} precio={cat.precio}  stock ={cat.stock} decuento={cat.decuento}/>
                })
              }
          </MDBCarouselItem>
    );
  }
  
  const ItemCarousel = ({idproducto, imagen,nombre, precio, stock , decuento}) => {
    const [activar, setActivar] = useState(false);
	  const [accordion, setAccordion] = useState(1);
    const [lista , setLista] = useState([]);
    const [modalAlerta, setModalAlerta] = useState(false);
    const [listaR , setListaR] = useState([]);
    const [datos , setDatos] = useState({ nombre : nombre , idproducto : idproducto})
    function getImage(urlImage) {
      if (!urlImage || urlImage ==='') {
        return 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg'
      }
      let ar = "" + urlImage
      ar = ar.replace('/var/www/html', url.serviceImg)
      return ar
    }

	
    function listaDetalleProducto() {

      let datos = {
        accion: 'detalles',
        llave: url.llavedefault,
        idproducto: idproducto
      }
      fetch(url.service+'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
        response.json()).then((responseJson) => {
          setLista(Array.from(responseJson));
          listaProductosRelacionados()
          
     })
    }
  
    function listaProductosRelacionados() {
      let datos = {
        accion: 'listarPorBusqueda',
        llave: url.llavedefault,
        idcategoria: nombre.substring(0, (nombre.length / 2) + 2)
      }
      fetch(url.service + 'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
        response.json()).then((responseJson) => {
          if (Array.from(responseJson).length < 3) {
              listaProductosRelacionadosTodos()
          }else{
            setListaR(Array.from(responseJson));
            setActivar(!activar)
          }
        });
    }


    function listaProductosRelacionadosTodos(l) {
      let datos = {
        accion: 'listarPorBusqueda',
        llave: url.llavedefault,
        idcategoria: nombre.substring(0, 2)
      }
      fetch(url.service + 'producto', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify(datos) }).then((response) =>
        response.json()).then((responseJson) => {
          setListaR(Array.from(responseJson));
          setActivar(!activar)
        });
    }
    function ObtenerImagen(pos) {
       let urlimagen = '';
       if (lista[0]) {
            try {
              urlimagen = getImage(lista[0].imagenes[pos].path)
          } catch (error) {
            urlimagen = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC' 
          }
       }
       return urlimagen;
    }
  
  
    function masDatos(){
      listaDetalleProducto();
    }
  
    function getCaracteristica(){
      let caracteristica = '';
      if (lista[0]) {
        try {
         
          lista[0].caracteristicas.forEach((element, index, array) => {
            caracteristica+= index +1 + ")    " + element.descripcion + '\n ' ;
        });
        } catch (error) {
          
        }
      }
      //console.log(caracteristica)
      return caracteristica;
    }
  
    function getEsptecnicas(){
      let esptecnicas = '';
      if (lista[0]) {
        try {
          lista[0].esptecnicas.forEach((element, index, array) => {
            esptecnicas+= index +1 + ")    " +element.descripcion + '\n' ;
           });
        } catch (error) {
          
        }
      }
      //console.log(caracteristica)
      return esptecnicas;
    }
  
    
    function AgregarCarrito() {
      if(stock > 0){
      let cadena = [
        {
          "idprod": idproducto,
          "nombrep":nombre,
          "cantidad": 1,
          "imagen": imagen,
          "precio":precio		  
        }]
  
      let ca=[]
      if(localStorage.getItem('CarritoJucumari')){
        ca=JSON.parse(localStorage.getItem('CarritoJucumari'))
        let c=0
        for (var i = 0; i < ca.length; i++) {
          if ((ca[i].idprod) == idproducto) {
            ca[i].cantidad=ca[i].cantidad+1
            c++
          }
        }
        if(c>0){
          localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
          window.dispatchEvent(new Event("storage"));
        }else{
          ca.push(cadena[0])
          localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
          window.dispatchEvent(new Event("storage"));
        }
      }else{ca.push(cadena[0])
        localStorage.setItem('CarritoJucumari', JSON.stringify(ca))
        window.dispatchEvent(new Event("storage"));}
    }else setModalAlerta(!modalAlerta);
    }
  
    return (
      
      <MDBCol md='4' >
      {/*<Link to ={`/detalleproducto/${idproducto}/${nombre}/${precio-((decuento/100)*precio)}/${stock}`}>*/}
      <MDBModal backdrop={false} isOpen={modalAlerta} toggle={()=>{
         setModalAlerta(!modalAlerta);
       }}>
            <MDBModalHeader toggle={()=>{
              setModalAlerta(!modalAlerta);
            }}>Producto sin Unidades</MDBModalHeader>
            <MDBModalBody>
                El producto seleccionado no tiene suficientes unidades 
                <MDBBtn color='primary' onClick={()=>{
                  setModalAlerta(!modalAlerta);
                }}>
                Aceptar
              </MDBBtn>
            </MDBModalBody>
           
          </MDBModal>
	<MDBModal size='lg' isOpen={activar} toggle={()=>{
           setActivar(!activar)
			}}>
            <MDBModalBody className='d-flex'>
              <MDBCol size='5' lg='5'>
                {/* Carrousel */}
                <MDBCarousel
                  activeItem={1}
                  length={3}
                  showControls
                  showIndicators
                  thumbnails
                  className='z-depth-1'
                >
                  <MDBCarouselInner>
                    <MDBCarouselItem itemId='1'>
                      <img
                        className='d-block w-100'
                        src={ObtenerImagen(0)}
                        alt='First slide' 
                      />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId='2'>
                      <img
                        className='d-block w-100'
                        src={ObtenerImagen(1)}
                        alt='Second slide'
                      />
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId='3'>
                      <img
                        className='d-block w-100'
                        src={ObtenerImagen(2)}
                        alt='Third slide'
                      />
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBCol>
              <MDBCol size='7' lg='7'>
                <h2 className='h2-responsive product-name'>
                  <strong>{nombre}</strong>
                </h2>
                <h4 className='h4-responsive'>
                {decuento > 0 &&
                      <span className='green-text'>
                      <strong>{precio-((decuento/100)*precio)}BS</strong>
                  </span>
                  }
                  { decuento > 0 &&
                      <span className='grey-text ml-1'>
                      <small>
                          <s>{precio}BS</s>
                      </small>
                    </span>
                  }
                   { decuento == 0 &&
                       <span className='green-text'>
                          <strong>{precio}BS</strong>
                      </span>
                  }
                </h4>
                {/* Accordion wrapper */}
                <div className='my-4'>
                  <MDBCard>
                    <MDBCollapseHeader onClick={()=>{
						let state ;
						let number  =  1;
						if (accordion !== number) {
						  state = number;
						} else {
						  state = false;
						}
						setAccordion(state);
					}}>
                      Caracteristicas
                      <i
                        className={`ml-1 ${
                          accordion === 1
                            ? 'fa fa-angle-down rotate-icon'
                            : 'fa fa-angle-down'
                        }`}
                      />
                    </MDBCollapseHeader>
                    <MDBCollapse isOpen={accordion === 1}>
                      <MDBCardBody>
                        {getCaracteristica()}
                      </MDBCardBody>
                    </MDBCollapse>
                  </MDBCard>
                  <MDBCard>
                    <MDBCollapseHeader onClick={()=>{
						let state ;
						let number  =  2;
						if (accordion !== number) {
						  state = number;
						} else {
						  state = false;
						}
						setAccordion(state)
					   } }>
                      Especificaciones Tecnicas
                      <i
                        className={`ml-1 ${
                          accordion === 2
                            ? 'fa fa-angle-down rotate-icon'
                            : 'fa fa-angle-down'
                        }`}
                      />
                    </MDBCollapseHeader>
                    <MDBCollapse isOpen={accordion === 2}>
                      <MDBCardBody>
                        {getEsptecnicas()}
                      </MDBCardBody>
                    </MDBCollapse>
                  </MDBCard>
                 
                </div>
                
                <MDBRow className='justify-content-center'>
                  <MDBBtn
                    color='secondary'
                    className='ml-4'
                    onClick={()=>{
						setActivar(!activar);
					}}
                  >
                    Cerrar
                  </MDBBtn>
                  <MDBBtn
                    color='primary'
                    className='ml-4'
                    onClick={()=>{
                      AgregarCarrito();
						setActivar(!activar);
					}}
                  >
                    Agregar al Carrito
                    <MDBIcon icon='cart-plus' className='ml-2' />
                  </MDBBtn>
                </MDBRow>
              </MDBCol>
            
            </MDBModalBody>
            <SectionContainer >
        <h2 className='h1-responsive font-weight-bold text-center my-5'>Productos Relacionados</h2>
        
        <MDBRow>
          <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
            <MDBCard narrow ecommerce className='mb-2' flat="true"  onClick={()=>{
              //console.log('====================================');
              //console.log(listaR);
              //console.log('====================================');
                 let url = "/categoria/@id/@nombre/2"+'?e='.concat(localStorage.getItem('empresa'));
                 url = url.replace('@id' , 1);
                 url = url.replace('@nombre',listaR[0].nombre)
                 window.location.assign(url)
                  }}>
              <MDBCardImage 
                className='d-block w-100'
                cascade
                src={listaR[0] ? listaR[0].imagen ?  listaR[0].imagen.replace('/var/www/html',url.serviceImg) : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg' : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg'}
                top
                alt='sample photo'
                style={{height:'250px'}}
              />
              <MDBCardBody cascade className='text-center'>
                <a href='#!' className='text-muted'>
                  <h5>{listaR[0] ? listaR[0].nombre : ''}</h5>
                </a>
              
                <MDBCardFooter className='px-1'>
                <span className='grey-text ml-1'>
                    <small>
					            	<s>{listaR[0] ? listaR[0].precio : ''}BS </s>
                    </small>
              </span> &nbsp;&nbsp;
              <span className='green-text'>
                        <strong>{listaR[0] ? listaR[0].precio-((listaR[0].decuento/100)*listaR[0].precio) : ''}BS</strong>
                </span>&nbsp;
                  <span className='float-right'>
                    <MDBTooltip domElement placement='top'>
                      <i className='fa fa-eye grey-text ml-3' />
                      <span>Quick look</span>
                    </MDBTooltip>
                    <MDBTooltip domElement placement='top'>
                      <i className='fa fa-heart grey-text ml-3' />
                      <span>Add to watchlist</span>
                    </MDBTooltip>
                  </span>
                </MDBCardFooter>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          {listaR[1] && 
              <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
              <MDBCard wide ecommerce onClick={()=>{
                let url = "/categoria/@id/@nombre/2"+'?e='.concat(localStorage.getItem('empresa'));
                url = url.replace('@id' , 1);
                url = url.replace('@nombre',listaR[1].nombre)
                window.location.assign(url)
              }} >
                <MDBCardImage
                  cascade
                  className='d-block w-100'
                  src={listaR[1] ? listaR[1].imagen ? listaR[1].imagen.replace('/var/www/html',url.serviceImg) : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg' : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg'}
                  top
                  alt='sample photo'
                  style={{height:'250px'}}
                />
                <MDBCardBody cascade className='text-center'>
                  <a href='#!' className='text-muted'>
                    <h5>{listaR[1] ? listaR[1].nombre : ''}</h5>
                  </a>
                
                  <MDBCardFooter className='px-1'>
                  <span className='grey-text ml-1'>
                      <small>
                          <s>{listaR[1] ? listaR[1].precio : ''}BS </s>
                      </small>
                </span> &nbsp;&nbsp;
                <span className='green-text'>
                          <strong>{listaR[1] ? listaR[1].precio-((listaR[1].decuento/100)*listaR[1].precio) : ''}BS</strong>
                  </span>&nbsp;
                    <span className='float-right'>
                      <MDBTooltip domElement placement='top'>
                        <i className='fa fa-eye grey-text ml-3' />
                        <span>Quick look</span>
                      </MDBTooltip>
                      <MDBTooltip domElement placement='top'>
                        <i className='fa fa-heart grey-text ml-3' />
                        <span>Add to watchlist</span>
                      </MDBTooltip>
                    </span>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          }
          {listaR[2] && 
              <MDBCol lg='4' md='12' className='mb-lg-0 mb-4'>
              <MDBCard wide ecommerce onClick={()=>{
                let url = "/categoria/@id/@nombre/2"+'?e='.concat(localStorage.getItem('empresa'));
                url = url.replace('@id' , 1);
                url = url.replace('@nombre',listaR[2].nombre)
                window.location.assign(url)
              }}>
                <MDBCardImage
                  cascade
                  className='d-block w-100'
                  src={listaR[2] ? listaR[2].imagen ? listaR[2].imagen.replace('/var/www/html',url.serviceImg) : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg' : 'http://dev.jucumari.mifacodi.com/empresas/react/sucursal1/image/categoria/noimagen.jpg'}
                  top
                  alt='sample photo'
                  style={{height:'250px'}}
                />
                <MDBCardBody cascade className='text-center'>
                  <a href='#!' className='text-muted'>
                    <h5>{listaR[2] ? listaR[2].nombre : ''}</h5>
                  </a>
                
                  <MDBCardFooter className='px-1'>
                  <span className='grey-text ml-1'>
                      <small>
                          <s>{listaR[2] ? listaR[2].precio : ''}BS </s>
                      </small>
                </span> &nbsp;&nbsp;
                <span className='green-text'>
                          <strong>{listaR[2] ? listaR[2].precio-((listaR[2].decuento/100)*listaR[2].precio) : ''}BS</strong>
                  </span>&nbsp;
                    <span className='float-right'>
                      <MDBTooltip domElement placement='top'>
                        <i className='fa fa-eye grey-text ml-3' />
                        <span>Quick look</span>
                      </MDBTooltip>
                      <MDBTooltip domElement placement='top'>
                        <i className='fa fa-heart grey-text ml-3' />
                        <span>Add to watchlist</span>
                      </MDBTooltip>
                    </span>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          }
          
        </MDBRow>
      </SectionContainer>
          </MDBModal>
       
      <MDBCard narrow ecommerce className='mb-2' flat="true" onClick={()=>{
      //	setActivar(!activar);
         masDatos()
			}}>
        <MDBCardImage
          cascade
          top
          src={getImage(imagen)}
          alt='sample photo'
          style={{height:'250px'}}
        />
        <MDBCardBody cascade>
          <MDBCardTitle>
            <strong>
              <a href='#!'>{datos.nombre}</a>
            </strong>
          </MDBCardTitle>
          <MDBCardFooter className='px-1'>
          <span className='grey-text ml-1'>
                    <small>
					            	<s>{precio}BS </s>
                    </small>
           </span> &nbsp;&nbsp;
           <span className='green-text'>
					        	<strong>{precio-((decuento/100)*precio)}BS</strong>
             </span>&nbsp;
             {tipo === 'ultimosAgregados' &&
             <MDBBadge pill color="danger">
             NEW
           </MDBBadge>}
            <span className='float-right'>
              <MDBTooltip domElement placement='top'>
                <i className='grey-text fa fa-eye mr-3' />
                <span>Quick Look</span>
              </MDBTooltip>
              <MDBTooltip domElement placement='top'>
                <i className='grey-text fa fa-heart' />
                <span>Add to Whishlist</span>
              </MDBTooltip>
            </span>
          </MDBCardFooter>
        </MDBCardBody>
      </MDBCard>
     {/* </Link> */}
    </MDBCol>
    );
  }
  return (
       
    <div>
     {listap.length >  0  && <section className="text-center my-5">
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          {titulo}
      </h2>
        <p className="grey-text text-center w-responsive mx-auto mb-5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
          error amet numquam iure provident voluptate esse quasi, veritatis
          totam voluptas nostrum quisquam eum porro a pariatur veniam.
      </p>
       {listap.length > 0 && 
            <MDBCarousel activeItem={1} length={listap.length / 3} slide={true} showControls={true} showIndicators={true} multiItem>
            <MDBCarouselInner>
              <MDBRow>
               {
                listaIndex(listap).map((categoria, index) => {
                    return <ItemCarouselList listaCategorias={categoria.datos} id={categoria.i} key = {index}/>
                  })
                }
              </MDBRow>
            </MDBCarouselInner>
          </MDBCarousel>
       }
      </section>
}
    </div>
  );
}

export default Productos;