import React, { useState } from 'react';
import { MDBDropdownItem ,MDBRow} from "mdbreact";
import url from '../config/Config';
import '../../components/styles/NavBar.css';
import { useHistory } from 'react-router-dom';

const CategoriaMenuList = ({ categorias }) => {
	const history = useHistory();

	const handleClick = (item) => {
		var url = `/categoria/${item.IdCategoria}/${item.Nombre}/1`+ '?e='.concat(localStorage.getItem('empresa'));
		history.push(url);
	};

	

	return (
		categorias.map((item, index) => {
			return (
				<div>
					<MDBDropdownItem style={{ width: '280px'}}>
						<div class="flex flex-col items-center h-full" onClick={() => handleClick(item)}>
							<img className="anchoImagen" src={item.Imagen.replace('/var/www/html', url.serviceImg)} alt="" />
							<div class="flex flex-row justify-center pl-2 pt-1 w-full">
									<div class="font-semibold h-2">{url.capitalizeWords(item.Nombre)}</div>
							</div>							
						</div>
					</MDBDropdownItem>
					{index < categorias.length - 1 && <hr />}
				</div>
			)
		})
	)

}

export default CategoriaMenuList