import React, { useState, initialArray } from "react";
//import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from "mdbreact";

import ProductosListItem from './ProductosListItem'

const ListaProductos = ({ listaProductos }) => {

  return (
            listaProductos.map((prod, index) => {
              return <ProductosListItem listaProductos={prod.datos} id={prod.i} key = {index}/>
            })

  );
}

export default ListaProductos;


